import { EnumServiceName } from '../../types'

export const determineExpandParameter = (serviceName: EnumServiceName) => {
  switch (serviceName) {
    case EnumServiceName.QuickInvoices:
      return 'contact,cc_product_transaction,ach_product_transaction,transactions,tags,created_user'
    case EnumServiceName.RecurringBillings:
      return 'account_vault,contact,payment_schedule,product_transaction,transactions,tags,created_user'
    case EnumServiceName.GatewayTransactionsReports:
      return 'contact,product_transaction,quick_invoice,tags'
    case EnumServiceName.Contacts:
      return 'created_user'
    default:
      return undefined
  }
}
