import {
  AdvancedFilterModel,
  FilterModel,
  SortModelItem,
} from 'ag-grid-community'
import { flatMap } from 'lodash'

import { FilterParams } from './datasource'

export type Filter = number | string | { [key: string]: string | number }

export type FixedFilters = {
  [key: string]: Filter | Filter[]
}

enum FilterByOperator {
  LIKE = '=', // This behaves as LIKE on the backend
  IN = 'IN',
  GREATER_OR_EQUAL = '>=',
  LESS_OR_EQUAL = '<=',
}

const buildFilter = (
  filterModel: FilterModel | AdvancedFilterModel,
  fixedFilter: FixedFilters
) => {
  const filter = Object.entries(filterModel).reduce((acc, [key, value]) => {
    const accumulator = acc as { [key: string]: any }

    if (value.filterType === 'text' && value.filter) {
      if (key.includes('.')) {
        const [key1, key2] = key.split('.')
        if (accumulator[key1]) {
          accumulator[key1][key2] = value.filter
        } else {
          accumulator[key1] = {}
          accumulator[key1][key2] = value.filter
        }
      } else {
        accumulator[key] = value.filter
      }
    } else if (value.filterType === 'date') {
      accumulator[key] = {}
      if (value.dateFrom) {
        accumulator[key]['$gte'] = new Date(value.dateFrom).getTime() / 1000
      }
      if (value.dateTo) {
        const dateTo = new Date(value.dateTo)
        dateTo.setDate(dateTo.getDate() + 1)
        accumulator[key]['$lte'] = dateTo.getTime() / 1000 - 1
      } else if (value.dateFrom && value.type !== 'greaterThan') {
        const dateTo = new Date(value.dateFrom)
        dateTo.setDate(dateTo.getDate() + 1)
        accumulator[key]['$lte'] = dateTo.getTime() / 1000 - 1
      }
      if (value.filter) {
        if (
          typeof value.filter === 'string' ||
          typeof value.filter === 'number'
        ) {
          accumulator[key] = value.filter
        } else {
          if (value.filter.$gte) {
            accumulator[key]['$gte'] = value.filter.$gte
          }
          if (value.filter.$lte) {
            accumulator[key]['$lte'] = value.filter.$lte
          }
        }
      }
    } else if (value.filterType === 'set') {
      if (value.values && value.values.length > 0) {
        if (key.includes('.')) {
          const [key1, key2] = key.split('.')
          if (accumulator[key1]) {
            accumulator[key1][key2] = value.values.join(',')
          } else {
            accumulator[key1] = {}
            accumulator[key1][key2] = value.values.join(',')
          }
        } else {
          accumulator[key] = value.values.join(',')
        }
      }
    } else if (value.filterType === 'number') {
      if (typeof value.filter === 'number') {
        value.filter = Math.round(value.filter)
      }
      if (key.includes('.')) {
        const [key1, key2] = key.split('.')
        if (accumulator[key1]) {
          accumulator[key1][key2] = value.filter
        } else {
          accumulator[key1] = {}
          accumulator[key1][key2] = value.filter
        }
      } else {
        accumulator[key] = value.filter
      }
    }
    return acc
  }, {})

  Object.entries(fixedFilter).forEach(([key, value]) => {
    if (typeof value === 'string' || typeof value === 'number') {
      filter[key] = value
    } else if (Array.isArray(value)) {
      filter[key] = value.join(',')
    } else if (typeof value === 'object') {
      if (key.includes('_ts') || key.includes('date')) {
        if (value.$gte) {
          filter[key] = filter[key] || {}
          filter[key]['$gte'] = value.$gte
        }
        if (value.$lte) {
          filter[key] = filter[key] || {}
          filter[key]['$lte'] = value.$lte
        }
      }
    }
  })

  return filter
}

const buildFilterBy = (
  filterModel: FilterModel | AdvancedFilterModel,
  fixedFilter: FixedFilters
) => {
  const filters = Object.entries(filterModel).map(([key, value]) => {
    if (value.filterType === 'text' || value.filterType === 'number') {
      if (typeof value.filter === 'number') {
        value.filter = Math.round(value.filter)
      }
      return {
        key,
        value: value.filter,
        operator: FilterByOperator.LIKE,
      }
    }
    if (value.filterType === 'set') {
      if (!value.values || value.values.length === 0) {
        return null
      }

      return {
        key,
        value: value.values.join(','),
        operator: FilterByOperator.IN,
      }
    }
    if (value.filterType === 'date' && typeof value.filter === 'object') {
      if (!value.filter.$gte && !value.filter.$lte) {
        return null
      }
      return [
        {
          key: key,
          value: value.filter.$gte,
          operator: FilterByOperator.GREATER_OR_EQUAL,
        },
        {
          key: key,
          value: value.filter.$lte,
          operator: FilterByOperator.LESS_OR_EQUAL,
        },
      ]
    }
  })

  const fixed = Object.entries(fixedFilter).map(([key, value]) => {
    if (typeof value === 'string' || typeof value === 'number') {
      return {
        key,
        value,
        operator: FilterByOperator.LIKE,
      }
    }
    if (Array.isArray(value)) {
      return {
        key,
        value: value.join(','),
        operator: FilterByOperator.IN,
      }
    }
    if (
      (key.includes('_ts') || key.includes('date')) &&
      typeof value === 'object'
    ) {
      return [
        value.$gte && {
          key,
          value: value.$gte,
          operator: FilterByOperator.GREATER_OR_EQUAL,
        },
        value.$lte && {
          key,
          value: value.$lte,
          operator: FilterByOperator.LESS_OR_EQUAL,
        },
      ]
    }
  })

  return flatMap(filters.concat(fixed)).filter((filter) => filter)
}

const buildSort = (sortModel: SortModelItem[]) => {
  const sort = sortModel.reduce((acc, { colId, sort }) => {
    const accumulator = acc as { [key: string]: any }
    if (colId.includes('.')) {
      const [colId1, colId2] = colId.split('.')
      accumulator[colId1] = {}
      accumulator[colId1][colId2] = sort
    } else {
      accumulator[colId] = sort
    }
    return acc
  }, {})
  return sort
}

const buildOrder = (sortModel: SortModelItem[]) => {
  const sort = sortModel.map(({ colId, sort }) => ({
    key: colId,
    operator: sort,
  }))

  return sort
}

export const filterSortBuilder = (
  filterModel: FilterModel | AdvancedFilterModel,
  sortModel: SortModelItem[],
  { filterVariant, fixedFilters }: FilterParams
) => {
  if (filterVariant === 'filter') {
    return {
      filter: buildFilter(filterModel, fixedFilters || {}),
      sort: buildSort(sortModel),
    }
  } else {
    return {
      filter_by: buildFilterBy(filterModel, fixedFilters || {}),
      order: buildOrder(sortModel),
    }
  }
}
