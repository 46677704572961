import {
  BodyScrollEndEvent,
  ColumnEverythingChangedEvent,
  ColumnVisibleEvent,
  FilterChangedEvent,
  GridApi,
  GridReadyEvent,
  GridSizeChangedEvent,
  PaginationChangedEvent,
  RowDataUpdatedEvent,
  SortChangedEvent,
  ColumnApi,
  DomLayoutType,
  FirstDataRenderedEvent,
} from 'ag-grid-community'
import { AgGridReact, AgGridReactProps } from 'ag-grid-react'
import { isNil, pickBy } from 'lodash'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { api } from '@shared/api/src'
import { ContextMenu, Button } from '@shared/components'
import AppTheme from '@shared/design'
import {
  LOCAL_STORAGE_SESSION_PAGE_SIZE_KEY,
  useEnforceLogin,
  useLocations,
  useReportFilters,
} from '@shared/hooks'
import { EnumServiceName } from '@shared/types'
import {
  downloadFile,
  formatSortModelForApi,
  transformFilters,
  determineExpandParameter,
} from '@shared/utils'

import PageSelector from './pagination-status-bar/PageSelector'
import PageSize from './pagination-status-bar/PageSize'

const useStyles = tss.withName('PaginateTable').create(({ theme }) => ({
  agGridContainer: {
    background: '#FFF',
    padding: '10px 20px',
    border: 'none',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    '& .ag-root-wrapper': {
      overflow: 'hidden',
      border: 'none',
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
    },
    '& .ag-footer': {
      flexShrink: 0,
    },
    '& .ag-header-cell-text': {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '700',
      lineHeight: 'normal',
      color: theme.palette['neutral-700'],
    },
    '& .ag-row': {
      backgroundColor: '#FFF',
    },
    '& .ag-row-odd': {
      backgroundColor: theme.palette['neutral-50'],
    },
    '& .ag-cell': {
      borderBottom: `1px solid ${theme.palette['neutral-200']}`,
    },
    '& .ag-cell-value': {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '400',
      color: theme.palette['neutral-700'],
    },
    '& .ag-pinned-right-cols-container': {
      marginRight: '0 !important',
    },
  },
  agGridContainerNoTopButtonBar: {
    padding: '20px 20px 40px',
  },
  buttonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '6px 0px 16px',
  },
  buttonsContainerSide: {
    display: 'flex',
    gap: '8px',
  },
  rowHover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
}))

// Max value that api supports is 5000
const MAX_ROWS_EXPORT = 3000

function PaginationTable<T>({
  showClearFiltersButton,
  showExportButton,
  primaryButtonData,
  showStatusBar = true,
  serviceName,
  getServiceID,
  extraParamsToExport,
  initialFilterModel,
  getGridRef,
  sizeColumnsToFit = false,
  onDataChange,
  guidingId,
  columnMinWidth = {},
  serverSideDatasource,
  ...AgGridReactProps
}: AgGridReactProps<T> & {
  showClearFiltersButton?: boolean
  showExportButton?: boolean
  primaryButtonData?: {
    text: string
    action: () => void
  }
  serviceName?: EnumServiceName
  getServiceID?: string
  extraParamsToExport?: object
  initialFilterModel?: any
  showStatusBar?: boolean
  getGridRef?: (ref: AgGridReact<T>) => void
  sizeColumnsToFit?: boolean
  onDataChange?: (hasData: boolean) => void
  guidingId?: string
  columnMinWidth?: { [key: string]: number }
}) {
  const { user } = useEnforceLogin()
  const { selectedLocation } = useLocations()
  const { setFilter, getFilter, clearFilter } = useReportFilters()
  const [customSize, setCustomSize] = useState(false)
  const [gridApi, setGridApi] = useState<GridApi<T>>(null)
  const { classes } = useStyles(AppTheme)
  const [filterModel, setFilterModel] = useState(initialFilterModel || {})
  const [sortModel, setSortModel] = useState([])
  const [isExporting, setIsExporting] = useState(false)
  const [hasData, setHasData] = useState(false)
  const [showTopButtonBar, setShowTopButtonBar] = useState<boolean>(false)
  const [previousLocation, setPreviousLocation] = useState(null)
  const [wrappedDatasource, setWrappedDatasource] = useState(null)
  const [domLayout, setDomLayout] = useState<DomLayoutType>('normal')
  const [savedFilterModel, setSavedFilterModel] = useState<any>(null)

  const { t } = useTranslation()
  const gridRef = useRef<AgGridReact<T>>(null)

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          statusPanel: PageSize,
          align: 'left',
          key: 'pageSize',
          statusPanelParams: {
            guidingId: guidingId,
          },
        },
        {
          statusPanel: PageSelector,
          align: 'right',
          key: 'pageSelector',
          statusPanelParams: {
            guidingId: guidingId,
          },
        },
      ],
    }
  }, [])

  const pageSizeOptions = useMemo(
    () => [
      { value: 5 },
      { value: 10 },
      { value: 15 },
      { value: 25 },
      { value: 50 },
      { value: 100 },
    ],
    []
  )

  // This function is to make sure that the default page size it's between the options
  const getClosestOption = useCallback(
    (value: number) =>
      pageSizeOptions.reduce((prev, curr) =>
        Math.abs(value - curr.value) < Math.abs(value - prev.value)
          ? curr
          : prev
      ).value,
    [pageSizeOptions]
  )

  const setPlaceholders = useCallback(
    (columnDefs) => {
      if (!gridApi) return

      const input = document.querySelectorAll(
        'input[type="text"], input[type="number"]'
      )
      input.forEach((item) => {
        let column = columnDefs.find(
          (column) =>
            item.ariaLabel?.includes(column.headerName) ||
            (column.filter == 'agDateColumnFilter' &&
              item.ariaLabel?.includes('Date'))
        )

        if (column) {
          if (column.filter == 'agDateColumnFilter') {
            item.setAttribute('placeholder', `${t('common.search')} Date`)
          } else {
            item.setAttribute(
              'placeholder',
              `${t('common.search')} ${column.headerName}...`
            )
          }
          item.setAttribute(
            'data-guiding-id',
            `${guidingId}-${(column.field as string)
              .toLocaleLowerCase()
              .replace(/\.|_/g, '-')}-filter`
          )
        }
      })

      const sorts = document.querySelectorAll('.ag-header-cell-sortable')

      sorts.forEach((item) => {
        const column = columnDefs.find(
          (col) => col.colId === item.getAttribute('col-id')
        )

        if (column) {
          item.setAttribute(
            'data-guiding-id',
            `${guidingId}-${(column.field as string)
              .toLocaleLowerCase()
              .replace(/\.|_/g, '-')}-sort`
          )
        }
      })
    },
    [gridApi, t]
  )

  useEffect(() => {
    if (!user || !gridApi) return

    const gridSize = localStorage.getItem(
      `fortis:session:results:${serviceName}:page-size`
    )

    gridApi.paginationSetPageSize(
      gridSize
        ? parseInt(gridSize)
        : localStorage.getItem(LOCAL_STORAGE_SESSION_PAGE_SIZE_KEY)
        ? getClosestOption(
            parseInt(localStorage.getItem(LOCAL_STORAGE_SESSION_PAGE_SIZE_KEY))
          )
        : 10
    )
  }, [user, gridApi])

  const onPaginationChanged = ({ api }: PaginationChangedEvent) => {
    if (!user || !gridApi) return

    if (serviceName) {
      localStorage.setItem(
        `fortis:session:results:${serviceName}:page-size`,
        api.paginationGetPageSize().toString()
      )
    }

    const body = document.querySelector('body')

    if (body.scrollHeight > body.clientHeight) {
      setCustomSize(true)
      setDomLayout('normal')
    } else {
      setCustomSize(false)
      setDomLayout('autoHeight')
    }
  }

  useEffect(() => {
    if (!gridApi) {
      return
    }

    if (domLayout) {
      gridApi.setDomLayout(domLayout)
    }

    if (savedFilterModel) {
      gridApi.setFilterModel(savedFilterModel)
    }
  }, [gridApi, domLayout, savedFilterModel])

  const resizeColumnsToFit = useCallback(
    (api: GridApi, columnApi: ColumnApi) => {
      const allColumnIds = columnApi
        .getAllDisplayedColumns()
        .map((column) => column.getColId())

      if (sizeColumnsToFit) {
        api.sizeColumnsToFit()
        return
      }

      columnApi.autoSizeColumns(allColumnIds)

      allColumnIds.forEach((colId) => {
        const currentWidth = columnApi
          .getColumnState()
          .find((col) => col.colId === colId)?.width

        if (colId === 'threeDots') {
          columnApi.applyColumnState({
            state: [
              {
                colId: 'threeDots',
                width: 52,
                pinned: 'right',
              },
            ],
            applyOrder: true,
          })
        } else if (
          colId.includes('email') ||
          colId.includes('_ts') ||
          colId.includes('created_user')
        ) {
          const minWidth = columnMinWidth[colId] || 220
          if (currentWidth < minWidth) {
            columnApi.setColumnWidth(colId, minWidth)
          }
        } else {
          columnApi.autoSizeColumn(colId)

          const updatedWidth = columnApi
            .getColumnState()
            .find((col) => col.colId === colId)?.width

          const minWidthForOtherColumns = Math.max(updatedWidth, 200)
          if (updatedWidth < minWidthForOtherColumns) {
            columnApi.setColumnWidth(colId, minWidthForOtherColumns)
          }
        }
      })
    },
    [columnMinWidth, sizeColumnsToFit]
  )

  const onFirstDataRendered = useCallback(
    (params: FirstDataRenderedEvent) => {
      resizeColumnsToFit(params.api, params.columnApi)
    },
    [resizeColumnsToFit]
  )

  const wrapServerSideDatasource = useCallback(
    (datasource) => {
      if (!datasource) return null

      return {
        ...datasource,
        getRows: (params) => {
          const originalSuccessCallback = params.success
          const originalFailCallback = params.fail

          params.success = function (result) {
            setHasData(result.rowData && result.rowData.length > 0)
            originalSuccessCallback(result)
          }

          params.fail = function () {
            setHasData(false)
            originalFailCallback()
          }

          datasource.getRows(params)
        },
      }
    },
    [setHasData]
  )

  useEffect(() => {
    const wrapped = wrapServerSideDatasource(serverSideDatasource)
    setWrappedDatasource(wrapped)
  }, [serverSideDatasource, wrapServerSideDatasource])

  const onGridReady = useCallback(
    async (params: GridReadyEvent) => {
      const { api, columnApi } = params

      setGridApi(api)

      resizeColumnsToFit(api, columnApi)

      if (getGridRef) {
        getGridRef(gridRef.current)
      }

      let savedFilters
      if (getFilter) {
        savedFilters = await getFilter(serviceName)
      }

      if (savedFilters && Object.keys(savedFilters).length > 0) {
        for (let key in savedFilters) {
          if (savedFilters.hasOwnProperty(key)) {
            if (
              key.includes('amount') &&
              savedFilters[key].filterType === 'number'
            ) {
              savedFilters[key].filter = savedFilters[key].filter / 100
            }
          }
        }
        setSavedFilterModel(savedFilters)
      } else {
        setSavedFilterModel(initialFilterModel)
      }
      api.addEventListener('modelUpdated', () => {
        if (!gridApi) {
          return
        }

        const rowData = []
        api.forEachNode((node) => rowData.push(node.data))
        const dataExists = rowData.length > 0
        setHasData(dataExists)
        if (onDataChange) {
          onDataChange(dataExists)
        }

        resizeColumnsToFit(api, params.columnApi)
      })

      const fixPinnedColumns = () => {
        const allColumnIds = columnApi
          .getAllDisplayedColumns()
          .map((col) => col.getColId())
        columnApi.autoSizeColumns(allColumnIds)

        columnApi.applyColumnState({
          state: [
            {
              colId: 'threeDots',
              width: 52,
              pinned: 'right',
            },
          ],
          applyOrder: true,
        })
      }

      fixPinnedColumns()
    },
    [
      resizeColumnsToFit,
      getGridRef,
      onDataChange,
      getFilter,
      serviceName,
      initialFilterModel,
    ]
  )

  useEffect(() => {
    if (selectedLocation?.id) {
      setPreviousLocation(selectedLocation)
    }
  }, [selectedLocation?.id])

  useEffect(() => {
    if (
      previousLocation &&
      selectedLocation &&
      previousLocation?.id !== selectedLocation?.id
    ) {
      clearFilters()
      gridApi?.setFilterModel(initialFilterModel)
      if (setFilter) {
        setFilter(serviceName, initialFilterModel)
      }
    }
  }, [selectedLocation, previousLocation])

  const fetchAndExportData = useCallback(
    async (format) => {
      if (!gridApi) {
        return
      }

      setIsExporting(true)

      let fieldsArray = gridApi
        .getColumnDefs()
        .filter((colDef: any) => !colDef.hide && colDef.field)
        .map((colDef: any) => colDef.field)

      //It is necessary to remove this field in the export on the batches screen
      //because the API does not accept its submission.
      if (serviceName === EnumServiceName.Batches) {
        fieldsArray = fieldsArray.filter(
          (field) => field !== 'total_net_amount'
        )
      }

      const fieldsString = fieldsArray.join(',')
      const locationFilter =
        selectedLocation?.id && serviceName !== EnumServiceName.Batches
          ? { location_id: selectedLocation.id }
          : {}
      let exportFilterModel = { ...filterModel }

      const combinedFilters = {
        ...exportFilterModel,
        ...locationFilter,
        ...extraParamsToExport,
      }

      const query = {
        filter: combinedFilters,
        sort: formatSortModelForApi(sortModel),
        page: {
          size: MAX_ROWS_EXPORT,
          number: 1,
        },
        _format: format,
        fields: fieldsString,
        expand: determineExpandParameter(serviceName),
      }

      const queryFiltered = pickBy(query, (value) => {
        if (isNil(value)) {
          return false
        }
        if (typeof value === 'object' && Object.keys(value).length === 0) {
          return false
        }
        return true
      })

      try {
        const data = await api.service(serviceName).export({
          getId: getServiceID ?? '',
          paginate: true,
          query: {
            ...queryFiltered,
          },
        })

        downloadFile(data, `${serviceName}.${format}`)
      } catch (error) {
        console.error('Error:', error)
      } finally {
        setIsExporting(false)
      }
    },
    [
      gridApi,
      selectedLocation,
      filterModel,
      sortModel,
      extraParamsToExport,
      getServiceID,
      serviceName,
    ]
  )

  const onColumnEverythingChanged = useCallback(
    (params: ColumnEverythingChangedEvent<T>) => {
      setPlaceholders(params.api.getColumnDefs())
    },
    [setPlaceholders]
  )
  const onBodyScrollEnd = useCallback(
    (params: BodyScrollEndEvent<T>) => {
      setPlaceholders(params.api.getColumnDefs())
    },
    [setPlaceholders]
  )
  const onGridSizeChanged = useCallback(
    (params: GridSizeChangedEvent<T>) => {
      setPlaceholders(params.api.getColumnDefs())
    },
    [setPlaceholders]
  )

  const onSortChanged = useCallback((params: SortChangedEvent<T>) => {
    const allColumnState = params.columnApi.getColumnState()
    const sortModel = allColumnState.filter((s) => s.sort != null)
    return setSortModel(sortModel)
  }, [])

  const onFilterChanged = useCallback(
    (params: FilterChangedEvent<T>) => {
      const agGridFilters = params.api.getFilterModel()
      const apiFilters = transformFilters(agGridFilters)
      setFilterModel(apiFilters)
      if (setFilter) {
        setFilter(serviceName, agGridFilters)
      }
    },
    [setFilter, serviceName]
  )

  const handleExportCSV = useCallback(() => {
    fetchAndExportData('csv')
  }, [fetchAndExportData])

  const handleExportTSV = useCallback(() => {
    fetchAndExportData('tsv')
  }, [fetchAndExportData])

  const clearFilters = () => {
    if (gridApi) {
      gridApi.setFilterModel(null)
      gridApi.onFilterChanged()
    }
    if (clearFilter) {
      clearFilter(serviceName)
    }
  }

  const onColumnVisible = useCallback(
    (params: ColumnVisibleEvent<T>) => {
      resizeColumnsToFit(params.api, params.columnApi)
    },
    [resizeColumnsToFit]
  )

  const onRowDataUpdated = useCallback(
    (params: RowDataUpdatedEvent<T>) => {
      resizeColumnsToFit(params.api, params.columnApi)
    },
    [resizeColumnsToFit]
  )

  const NoRowsOverlay = () => (
    <div className="ag-overlay-loading-center">
      <p className="far fa-frown">{t('common.not-available-results')}</p>
    </div>
  )
  const popupParent = useMemo<HTMLElement | null>(() => {
    return document.querySelector('body')
  }, [])

  const localeText = useMemo<{
    [key: string]: string
  }>(() => {
    return {
      blank: t('common.ag-grid.blank'),
      contains: t('common.ag-grid.contains'),
      copy: t('common.ag-grid.copy'),
      copyWithGroupHeaders: t('common.ag-grid.copy-with-group-headers'),
      copyWithHeaders: t('common.ag-grid.copy-with-headers'),
      endsWith: t('common.ag-grid.ends-with'),
      equals: t('common.ag-grid.equals'),
      filterOoo: t('common.ag-grid.filterOoo'),
      greaterThan: t('common.ag-grid.greater-than'),
      greaterThanOrEqual: t('common.ag-grid.greater-than-or-equal'),
      inRange: t('common.ag-grid.between'),
      lessThan: t('common.ag-grid.less-than'),
      lessThanOrEqual: t('common.ag-grid.less-than-or-equal'),
      notBlank: t('common.ag-grid.blank-not'),
      notContains: t('common.ag-grid.contains-not'),
      notEqual: t('common.ag-grid.equals-not'),
      paste: t('common.ag-grid.paste'),
      searchOoo: t('common.ag-grid.searchOoo'),
      selectAll: t('common.ag-grid.select-all'),
      startsWith: t('common.ag-grid.starts-with'),
    }
  }, [])

  useEffect(() => {
    if (showClearFiltersButton || showExportButton || primaryButtonData) {
      setShowTopButtonBar(true)
    } else {
      setShowTopButtonBar(false)
    }
  }, [showClearFiltersButton, showExportButton, primaryButtonData])

  return (
    <div
      className={`ag-theme-alpine ${classes.agGridContainer} ${
        !showTopButtonBar ? classes.agGridContainerNoTopButtonBar : ''
      }`}
      style={{
        height: customSize ? '80vh' : 'auto',
      }}
    >
      {showTopButtonBar && (
        <div className={classes.buttonsContainer}>
          <div className={classes.buttonsContainerSide}>
            {showClearFiltersButton && (
              <Button
                label={t('common.filters-clear')}
                color="secondary"
                onClick={clearFilters}
                guidingId={`${guidingId}-clearfilters`}
              />
            )}
            {showExportButton && (
              <ContextMenu
                options={[
                  {
                    label: t('common.export-file-csv'),
                    action: handleExportCSV,
                    guidingId: `${guidingId}-export-csv`,
                  },
                  {
                    label: t('common.export-file-tsv'),
                    action: handleExportTSV,
                    guidingId: `${guidingId}-export-tsv`,
                  },
                ]}
                showIcon={false}
                buttonText={t('common.export')}
                loading={isExporting}
                disabled={!hasData}
                guidingId={`${guidingId}-export`}
              />
            )}
          </div>
          {primaryButtonData && (
            <div className={classes.buttonsContainerSide}>
              <Button
                label={primaryButtonData.text}
                onClick={primaryButtonData.action}
                guidingId={`${guidingId}-primary-button`}
              />
            </div>
          )}
        </div>
      )}

      <AgGridReact<T>
        {...AgGridReactProps}
        ref={gridRef}
        animateRows={true}
        localeText={localeText}
        noRowsOverlayComponent={NoRowsOverlay}
        onFilterChanged={onFilterChanged}
        onFirstDataRendered={onFirstDataRendered}
        onBodyScrollEnd={onBodyScrollEnd}
        onColumnEverythingChanged={onColumnEverythingChanged}
        onGridReady={onGridReady}
        onGridSizeChanged={onGridSizeChanged}
        onPaginationChanged={onPaginationChanged}
        onSortChanged={onSortChanged}
        onColumnVisible={onColumnVisible}
        onRowDataUpdated={onRowDataUpdated}
        pagination={true}
        popupParent={popupParent}
        statusBar={showStatusBar ? statusBar : undefined}
        suppressClipboardPaste={true}
        suppressCopyRowsToClipboard={true}
        suppressCsvExport={true}
        suppressCutToClipboard={true}
        suppressExcelExport={true}
        suppressPaginationPanel={true}
        blockLoadDebounceMillis={100}
        serverSideDatasource={wrappedDatasource}
        rowClassRules={{
          [classes.rowHover]: () => !!AgGridReactProps.onRowClicked,
        }}
      />
    </div>
  )
}

export default PaginationTable
