import { ReactElement, forwardRef } from 'react'

/**
 *
 * Implementation of React.forwardRef that allows the use of Generic Components.
 * See https://www.totaltypescript.com/forwardref-with-generic-components
 */
export function genericForwardRef<T, P = {}>(
  render: (props: P, ref: React.Ref<T>) => ReactElement
): (props: P & React.RefAttributes<T>) => ReactElement {
  return forwardRef(render) as any
}
