import { SvgIcon } from '@mui/material'

export const NegativeIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="64"
        height="65"
        viewBox="0 0 64 65"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect y="0.560303" width="64" height="64" rx="32" fill="#FEE2E2" />
        <g clipPath="url(#clip0_11471_36646)">
          <path
            d="M32 45.8937C24.636 45.8937 18.6666 39.9244 18.6666 32.5604C18.6666 25.1964 24.636 19.2271 32 19.2271C39.364 19.2271 45.3333 25.1964 45.3333 32.5604C45.3333 39.9244 39.364 45.8937 32 45.8937ZM30.6666 36.5604V39.2271H33.3333V36.5604H30.6666ZM30.6666 25.8937V33.8937H33.3333V25.8937H30.6666Z"
            fill="#EF4444"
          />
        </g>
        <defs>
          <clipPath id="clip0_11471_36646">
            <rect
              width="32"
              height="32"
              fill="white"
              transform="translate(16 16.5603)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}
