import React, { useState, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import SearchIcon from '@mui/icons-material/Search'
import {
  Grid,
  InputLabel,
  TextField,
  Checkbox,
  ListItemText,
  MenuItem as MuiMenuItem,
  InputAdornment,
  Button,
  Popper,
  Paper,
  ClickAwayListener,
  ListSubheader,
} from '@mui/material'

import { ContactFields, TokenFields } from '@shared/utils'

const useStyles = tss
  .withName('FieldMappingMultiSelect')
  .create(({ theme }) => ({
    orangeCheckbox: {
      '&.Mui-checked': {
        color: theme.palette.common.white,
        '& .MuiSvgIcon-root': {
          fill: theme.palette['power-orange'],
        },
      },
      '& .MuiSvgIcon-root': {
        borderRadius: '4px',
      },
    },
    menuItem: {
      '&.Mui-selected': {
        backgroundColor: 'inherit',
        color: theme.palette['neutral-700'],
      },
      color: theme.palette['neutral-700'],
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '20px',
    },
    listItemText: {
      color: theme.palette['neutral-700'],
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
    },
    requiredAsterisk: {
      color: theme.palette['negative'],
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 500,
      lineHeight: '20px',
      marginLeft: '4px',
    },
    selectButton: {
      width: '100%',
      textAlign: 'left',
      padding: '10px 12px',
      border: '1px solid #D1D5DB',
      borderRadius: '4px',
      backgroundColor: '#FFF',
      color: '#6B7280',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      '&:hover': {
        borderColor: '#9CA3AF',
      },
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    selectLabel: {
      color: '#6B7280',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: 500,
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
    },
    searchField: {
      borderBottom: `1px solid ${theme.palette.divider}`,
      backgroundColor: theme.palette.common.white,
      zIndex: 1,
    },
    popperPaper: {
      maxHeight: 400,
      overflow: 'auto',
      marginTop: 8,
      width: '100%',
      boxSizing: 'border-box',
    },
  }))

interface FieldMappingMultiSelectProps {
  label: string
  options: { label: string; value: string; category: string }[]
  name: string
  value: string[]
  onChange: (event: any) => void
  error?: boolean
  guidingId?: string
}

const requiredFields = [
  'last_name',
  'account_number',
  'exp_date',
  'account_type',
  'account_holder_name',
  'routing_number',
]

export const FieldMappingMultiSelect: React.FC<
  FieldMappingMultiSelectProps
> = ({ label, options, name, value = [], onChange, guidingId }) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const [searchTerm, setSearchTerm] = useState('')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const inputRef = useRef(null)

  const filteredTokenFields = TokenFields.filter((option) =>
    option.name.toLowerCase().includes(searchTerm.toLowerCase())
  )
  const filteredContactFields = ContactFields.filter((option) =>
    option.name.toLowerCase().includes(searchTerm.toLowerCase())
  )

  const expirationFields = ['exp_date', 'exp_date.month', 'exp_date.year']

  const shouldDisableOption = (optionKey: string) => {
    if (value.includes('exp_date')) {
      return expirationFields.slice(1).includes(optionKey)
    }
    if (value.some((val) => expirationFields.slice(1).includes(val))) {
      return optionKey === 'exp_date'
    }
    return false
  }

  const handleToggle = (optionKey: string) => {
    const currentIndex = value.indexOf(optionKey)
    const newChecked = [...value]

    if (currentIndex === -1) {
      newChecked.push(optionKey)
    } else {
      newChecked.splice(currentIndex, 1)
    }

    onChange({ target: { value: newChecked } })
  }

  const renderSelectedValue = (selected: string[]) => {
    if (selected.length === 0) {
      return t(
        'ftp-feature-mfe-token.token-import.select-header-that-matches-this-information'
      )
    }
    if (selected.length === 1) {
      const selectedOption = [...TokenFields, ...ContactFields].find(
        (option) => option.key === selected[0]
      )
      return selectedOption ? selectedOption.name : ''
    }
    return `${selected.length} headers selected`
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <Grid item xs={12}>
      {label && (
        <InputLabel htmlFor={name} className={classes.selectLabel}>
          {label}
          {requiredFields.includes(name) && (
            <span className={classes.requiredAsterisk}>*</span>
          )}
        </InputLabel>
      )}
      <Button
        onClick={handleClick}
        className={classes.selectButton}
        disableRipple
        endIcon={<ArrowDropDownIcon />}
        data-testid="test-select"
        data-guiding-id={`${guidingId}-select`}
      >
        {renderSelectedValue(value)}
      </Button>
      <Popper
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        role={undefined}
        placement="bottom-start"
        style={{ width: anchorEl?.offsetWidth }}
        data-guiding-id={`${guidingId}-popper`}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper className={classes.popperPaper}>
            <ListSubheader>
              <TextField
                ref={inputRef}
                size="small"
                placeholder="Search"
                fullWidth
                onChange={(e) => setSearchTerm(e.target.value)}
                value={searchTerm}
                variant="outlined"
                className={classes.searchField}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  style: { color: '#6B7280' },
                }}
                inputProps={{
                  style: { color: '#6B7280' },
                }}
              />
            </ListSubheader>
            {value.length > 0 && (
              <ListSubheader style={{ pointerEvents: 'none' }}>
                Selected Headers
              </ListSubheader>
            )}
            {value.map((selectedKey) => {
              const selectedOption = [...TokenFields, ...ContactFields].find(
                (option) => option.key === selectedKey
              )
              return selectedOption ? (
                <MuiMenuItem key={selectedOption.key}>
                  <Checkbox
                    checked={value.indexOf(selectedOption.key) > -1}
                    onClick={() => handleToggle(selectedOption.key)}
                    className={classes.orangeCheckbox}
                  />
                  <ListItemText primary={selectedOption.name} />
                </MuiMenuItem>
              ) : null
            })}
            <ListSubheader>Token</ListSubheader>
            {filteredTokenFields.map((option, index) => (
              <MuiMenuItem
                key={option.key}
                value={option.key}
                onClick={() => handleToggle(option.key)}
                className={classes.menuItem}
                disabled={shouldDisableOption(option.key)}
                data-guiding-id={`${guidingId}-tokenoption-${index}`}
              >
                <Checkbox
                  checked={value.indexOf(option.key) > -1}
                  className={classes.orangeCheckbox}
                  data-guiding-id={`${guidingId}-tokencheckbox-${index}`}
                />
                <ListItemText
                  primary={
                    <span className={classes.listItemText}>
                      {option.name}
                      {requiredFields.includes(option.key) && (
                        <span className={classes.requiredAsterisk}>*</span>
                      )}
                    </span>
                  }
                />
              </MuiMenuItem>
            ))}
            <ListSubheader>Contact</ListSubheader>
            {filteredContactFields.map((option, index) => (
              <MuiMenuItem
                key={option.key}
                value={option.key}
                onClick={() => handleToggle(option.key)}
                className={classes.menuItem}
                data-guiding-id={`${guidingId}-contactoption-${index}`}
              >
                <Checkbox
                  checked={value.indexOf(option.key) > -1}
                  className={classes.orangeCheckbox}
                  data-guiding-id={`${guidingId}-contactcheckbox-${index}`}
                />
                <ListItemText
                  primary={
                    <span className={classes.listItemText}>
                      {option.name}
                      {requiredFields.includes(option.key) && (
                        <span className={classes.requiredAsterisk}>*</span>
                      )}
                    </span>
                  }
                />
              </MuiMenuItem>
            ))}
          </Paper>
        </ClickAwayListener>
      </Popper>
    </Grid>
  )
}
