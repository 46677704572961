export const NoTokensImported = () => (
  <svg
    width="76"
    height="75"
    viewBox="0 0 76 75"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" width="75" height="75" rx="37.5" fill="#E0F2FE" />
    <path
      d="M29.9355 21.3714H28.3226C23.4838 21.3714 21.8709 24.2585 21.8709 27.823V29.4359V52.0165C21.8709 53.3552 23.3871 54.1133 24.4516 53.3068L27.2097 51.2423C27.8548 50.7585 28.758 50.823 29.3387 51.4036L32.0161 54.0972C32.6451 54.7262 33.6774 54.7262 34.3064 54.0972L37.0161 51.3875C37.5806 50.823 38.4838 50.7585 39.1129 51.2423L41.8709 53.3068C42.9355 54.0972 44.4516 53.3391 44.4516 52.0165V24.5972C44.4516 22.823 45.9032 21.3714 47.6774 21.3714H29.9355ZM28.2742 40.7423C27.3871 40.7423 26.6613 40.0165 26.6613 39.1294C26.6613 38.2423 27.3871 37.5165 28.2742 37.5165C29.1613 37.5165 29.8871 38.2423 29.8871 39.1294C29.8871 40.0165 29.1613 40.7423 28.2742 40.7423ZM28.2742 34.2907C27.3871 34.2907 26.6613 33.5649 26.6613 32.6778C26.6613 31.7907 27.3871 31.0649 28.2742 31.0649C29.1613 31.0649 29.8871 31.7907 29.8871 32.6778C29.8871 33.5649 29.1613 34.2907 28.2742 34.2907ZM38 40.3391H33.1613C32.5 40.3391 31.9516 39.7907 31.9516 39.1294C31.9516 38.4681 32.5 37.9198 33.1613 37.9198H38C38.6613 37.9198 39.2097 38.4681 39.2097 39.1294C39.2097 39.7907 38.6613 40.3391 38 40.3391ZM38 33.8875H33.1613C32.5 33.8875 31.9516 33.3391 31.9516 32.6778C31.9516 32.0165 32.5 31.4681 33.1613 31.4681H38C38.6613 31.4681 39.2097 32.0165 39.2097 32.6778C39.2097 33.3391 38.6613 33.8875 38 33.8875Z"
      fill="#0EA5E9"
    />
    <path
      d="M47.6936 21.3714V23.7907C48.7581 23.7907 49.7742 24.2262 50.5162 24.952C51.2904 25.7423 51.7097 26.7585 51.7097 27.823V31.7262C51.7097 32.9198 51.1775 33.4681 49.9678 33.4681H46.871V24.6133C46.871 24.1617 47.242 23.7907 47.6936 23.7907V21.3714ZM47.6936 21.3714C45.9033 21.3714 44.4517 22.823 44.4517 24.6133V35.8875H49.9678C52.5162 35.8875 54.1291 34.2746 54.1291 31.7262V27.823C54.1291 26.0488 53.4033 24.4359 52.242 23.2585C51.0646 22.0972 49.4678 21.3875 47.6936 21.3714C47.7097 21.3714 47.6936 21.3714 47.6936 21.3714Z"
      fill="#0EA5E9"
    />
  </svg>
)
