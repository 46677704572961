import { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  useTheme,
} from '@mui/material'

import { ContactFields, TokenFields } from '@shared/utils'

import { FieldMappingMultiSelect } from '@/components/field-mapping-multi-select/FieldMappingMultiSelect'

const useStyles = tss.withName('FileImportPreview').create(({ theme }) => ({
  container: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    boxShadow: 'none',
    border: 'none',
  },
  tableContainer: {
    display: 'flex',
    flexDirection: 'column',
    border: 'none',
    overflowX: 'auto',
    width: '50%',
    boxShadow: 'none',
  },
  previewContainerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
  },
  tableCell: {
    borderBottom: 'none',
    padding: '6px 16px',
  },
  divCell: {
    display: 'inline-flex',
    flexDirection: 'column',
    position: 'relative',
    padding: '8px',
    margin: '0 10px 4px 0',
    height: '44px',
    minWidth: '150px',
    width: '240px',
    verticalAlign: 'top',
    border: `1px solid ${theme.palette['neutral-200']}`,
    background: `${theme.palette.common.white}`,
    textAlign: 'center',
    color: `${theme.palette['neutral-700']}`,
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    wordWrap: 'break-word',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  headerCell: {
    color: `${theme.palette['neutral-700']}`,
    fontFamily: 'Inter',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '28px',
    padding: '16px',
  },
  labelCell: {
    color: `${theme.palette['neutral-700']}`,
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '28px',
    padding: '16px',
  },
  previewContainer: {
    overflowX: 'auto',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxShadow: 'none',
    border: 'none',
    width: '100%',
  },
  previewTitle: {
    background: `${theme.palette.common.white}`,
    padding: '16px',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 500,
    lineHeight: '28px',
    color: `${theme.palette['neutral-700']}`,
  },
}))

const FileImportPreview = ({
  data,
  externalSelectedHeaders = {},
  onHeadersChange,
  errorsByHeader,
  onDryRunButtonDisabled,
}) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const columnHeaders =
    Array.isArray(data?.list) && data.list.length > 0
      ? Object.keys(data.list[0])
      : []

  const mapExternalHeadersToSelected = (externalHeaders) => {
    const mappedHeaders = {}
    Object.keys(externalHeaders.contact || {}).forEach((key) => {
      if (typeof externalHeaders.contact[key] === 'string') {
        mappedHeaders[externalHeaders.contact[key].toLowerCase()] = [key]
      }
    })

    Object.keys(externalHeaders.token || {}).forEach((key) => {
      const tokenValue = externalHeaders.token[key]

      if (typeof tokenValue === 'string') {
        mappedHeaders[tokenValue.toLowerCase()] = [key]
      } else if (typeof tokenValue === 'object' && tokenValue !== null) {
        Object.keys(tokenValue).forEach((subKey) => {
          if (typeof tokenValue[subKey] === 'string') {
            mappedHeaders[tokenValue[subKey].toLowerCase()] = [
              `${key}.${subKey}`,
            ]
          }
        })
      }
    })

    return mappedHeaders
  }

  const [selectedHeaders, setSelectedHeaders] = useState(
    columnHeaders.reduce(
      (acc, header) => ({
        ...acc,
        [header]: [],
      }),
      {}
    )
  )

  const hasMatchingHeaders = (columnHeaders, externalSelectedHeaders) => {
    const selectedValues = Object.values(
      externalSelectedHeaders.token || {}
    ).concat(Object.values(externalSelectedHeaders.contact || {}))

    return columnHeaders.some((header) => selectedValues.includes(header))
  }

  const hasVisibleSelection = (headersDisplayedOnScreen, newHeaders) => {
    return headersDisplayedOnScreen.some((header) => {
      return Array.isArray(newHeaders[header]) && newHeaders[header].length > 0
    })
  }

  useEffect(() => {
    if (Object.keys(externalSelectedHeaders).length > 0) {
      const mappedHeaders = mapExternalHeadersToSelected(
        externalSelectedHeaders
      )

      setSelectedHeaders((prevHeaders) => {
        const newHeaders = { ...prevHeaders }

        Object.keys(mappedHeaders).forEach((headerKey) => {
          const existingValues = newHeaders[headerKey.toLowerCase()] || []
          const newValues = mappedHeaders[headerKey.toLowerCase()]

          const combinedValues = existingValues.concat(
            newValues.filter((value) => !existingValues.includes(value))
          )

          newHeaders[headerKey.toLowerCase()] = combinedValues
        })

        const isDryRunDisabled = !hasVisibleSelection(columnHeaders, newHeaders)

        onDryRunButtonDisabled(isDryRunDisabled)

        return newHeaders
      })
    } else {
      onDryRunButtonDisabled(true)
    }
  }, [externalSelectedHeaders])

  const handleHeaderChange = (event, column) => {
    const newValue = event.target.value || []
    const updatedHeaders = {
      ...selectedHeaders,
      [column.toLowerCase()]: Array.isArray(newValue) ? newValue : [newValue],
    }
    setSelectedHeaders(updatedHeaders)
    const payload = generateSelectedHeaders(updatedHeaders)
    onHeadersChange(payload)
  }

  const transformOptions = (options, category) =>
    options.map((option) => ({
      label: option.name,
      value: option.key,
      category,
    }))

  const options = [
    ...transformOptions(ContactFields, 'ContactFields'),
    ...transformOptions(TokenFields, 'TokenFields'),
  ]

  const generateSelectedHeaders = (headers) => {
    const fieldMappings = {
      contact: {},
      token: {},
    }

    const addFieldMapping = (mappings, field, key) => {
      const fieldKey = field.replace('_contact', '').replace('_token', '')
      const keys = fieldKey.split('.')
      if (keys.length > 1) {
        const [parentKey, childKey] = keys
        if (!mappings[parentKey]) {
          mappings[parentKey] = {}
        }
        mappings[parentKey][childKey] = key
      } else {
        mappings[fieldKey] = key
      }
    }

    Object.keys(headers).forEach((header) => {
      headers[header].forEach((field) => {
        const contactField = ContactFields.find((f) => f.key === field)
        const tokenField = TokenFields.find((f) => f.key === field)
        const fieldKey = contactField
          ? contactField.key
          : tokenField
          ? tokenField.key
          : field

        if (ContactFields.find((f) => f.key === field)) {
          addFieldMapping(fieldMappings.contact, fieldKey, header)
        } else if (TokenFields.find((f) => f.key === field)) {
          addFieldMapping(fieldMappings.token, fieldKey, header)
        }
      })
    })

    return fieldMappings
  }

  useEffect(() => {
    if (Object.keys(externalSelectedHeaders).length > 0) {
      const mappedHeaders = mapExternalHeadersToSelected(
        externalSelectedHeaders
      )

      setSelectedHeaders(mappedHeaders)
      if (hasMatchingHeaders(columnHeaders, externalSelectedHeaders)) {
        const payload = generateSelectedHeaders(mappedHeaders)
        onHeadersChange(payload)
      } else {
        onHeadersChange({})
        onDryRunButtonDisabled(true)
      }
    }
  }, [externalSelectedHeaders])

  return (
    <Box className={classes.container}>
      <TableContainer component={Paper} className={classes.tableContainer}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className={`${classes.tableCell} ${classes.headerCell}`}
              >
                {t('ftp-feature-mfe-token.token-import.file-column-header')}
              </TableCell>
              <TableCell
                className={`${classes.tableCell} ${classes.headerCell}`}
              >
                {t('ftp-feature-mfe-token.token-import.gateway-field-header')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {columnHeaders.map((column, index) => {
              return (
                <TableRow key={index}>
                  <TableCell
                    className={`${classes.tableCell} ${classes.labelCell}`}
                  >
                    {column}
                  </TableCell>
                  <TableCell className={classes.tableCell}>
                    <FieldMappingMultiSelect
                      label=""
                      options={options}
                      name={column}
                      value={selectedHeaders[column.toLowerCase()]}
                      onChange={(event) => handleHeaderChange(event, column)}
                      guidingId={`tokenimport-fieldmapping`}
                    />
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box className={classes.previewContainerWrapper}>
        <div className={classes.previewTitle}>
          {t('ftp-feature-mfe-token.token-import.import-file-preview')}
        </div>
        <TableContainer component={Paper} className={classes.previewContainer}>
          <Table>
            <TableBody>
              {columnHeaders.map((column, index) => (
                <TableRow key={index}>
                  <TableCell className={classes.tableCell}>
                    {data.list.map((row, rowIndex) => {
                      return (
                        <div key={rowIndex} className={`${classes.divCell}`}>
                          {row[column]}
                        </div>
                      )
                    })}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}

export default FileImportPreview
