import { useEffect, useState } from 'react'

import { useAuthorization } from '../../hooks/useAuthorization'
import {
  UserPermissionSet,
  checkAllPermissions,
  checkPermission,
  checkSomePermissions,
} from '../../utils/permissions/permissions'

export type HasPermissionProps = {
  children: JSX.Element
  permission?: string
  somePermissions?: string[]
  allPermissions?: string[]
  unauthorizedComponent?: JSX.Element | null
}

function hasPermission(
  userPermissions: UserPermissionSet,
  permission?: string,
  allPermissions?: string[],
  somePermissions?: string[]
): boolean {
  if (
    (permission && !checkPermission(userPermissions, permission)) ||
    (allPermissions &&
      !checkAllPermissions(userPermissions, ...allPermissions)) ||
    (somePermissions &&
      !checkSomePermissions(userPermissions, ...somePermissions))
  )
    return false
  return true
}

export function HasPermission({
  children,
  permission,
  allPermissions,
  somePermissions,
  unauthorizedComponent = null,
}: HasPermissionProps) {
  const { userPermissionSet } = useAuthorization()

  const [permissionGranted, setPermissionGranted] = useState<boolean>(false)

  useEffect(() => {
    setPermissionGranted(
      hasPermission(
        userPermissionSet,
        permission,
        allPermissions,
        somePermissions
      )
    )
  }, [userPermissionSet])

  return permissionGranted ? children : unauthorizedComponent
}
