import { SvgIcon, SvgIconProps } from '@mui/material'

export const SuccessIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="32" height="32" rx="16" fill="#34D399" />
        <g clipPath="url(#clip0_4650_94335)">
          <path
            d="M14.3341 18.6446L21.9941 10.9838L23.1733 12.1621L14.3341 21.0013L9.03076 15.6979L10.2091 14.5196L14.3341 18.6446Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_4650_94335">
            <rect
              width="20"
              height="20"
              fill="white"
              transform="translate(6 6)"
            />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}
