export const filtersFuture = [
  {
    id: '',
    title: 'common.filter-all-time',
  },
  {
    id: 'today',
    title: 'common.filter-today',
  },
  {
    id: 'tomorrow',
    title: 'common.filter-tomorrow',
  },
  {
    id: 'this week',
    title: 'common.filter-this-week',
  },
  {
    id: 'next week',
    title: 'common.filter-next-week',
  },
  {
    id: 'this month',
    title: 'common.filter-this-month',
  },
  {
    id: 'next month',
    title: 'common.filter-next-month',
  },
  {
    id: 'next 30 days',
    title: 'common.filter-next-30-days',
  },
  {
    id: 'custom',
    title: 'common.filter-custom',
  },
]

export const filtersPast = [
  {
    id: '',
    title: 'common.filter-all-time',
  },
  {
    id: 'today',
    title: 'common.filter-today',
  },
  {
    id: 'yesterday',
    title: 'common.filter-yesterday',
  },
  {
    id: 'this week',
    title: 'common.filter-this-week',
  },
  {
    id: 'last week',
    title: 'common.filter-last-week',
  },
  {
    id: 'this month',
    title: 'common.filter-this-month',
  },
  {
    id: 'last month',
    title: 'common.filter-last-month',
  },
  {
    id: 'last 30 days',
    title: 'common.filter-last-30-days',
  },
  {
    id: 'custom',
    title: 'common.filter-custom',
  },
]

export const filtersAll = [
  {
    id: '',
    title: 'common.filter-all-time',
  },
  {
    id: 'last month',
    title: 'common.filter-last-month',
  },
  {
    id: 'last week',
    title: 'common.filter-last-week',
  },
  {
    id: 'yesterday',
    title: 'common.filter-yesterday',
  },
  {
    id: 'today',
    title: 'common.filter-today',
  },
  {
    id: 'tomorrow',
    title: 'common.filter-tomorrow',
  },
  {
    id: 'next week',
    title: 'common.filter-next-week',
  },
  {
    id: 'next month',
    title: 'common.filter-next-month',
  },
  {
    id: 'custom',
    title: 'common.filter-custom',
  },
]
