import {
  tokenImportCodes,
  TokenImportType,
} from '../types/tokenImportStatusCodes'

export const codeToTokenImportStatus = (
  code: string | null | undefined
): TokenImportType => {
  if (!code) return <TokenImportType>''
  return tokenImportCodes[code.toString()]
}
