import { useEffect, useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { Typography, Grid, Box, AppBar, Paper } from '@mui/material'

import { api, Location, TokenImport, TokenImportDetails } from '@shared/api/src'
import {
  ButtonBarEnd,
  Button,
  ButtonBar,
  HasPermission,
  UserNotAllowed,
  Loading,
  TokenImportStatus,
  ActionModal,
} from '@shared/components'
import { useFtpPortalHubCommunication, useNotification } from '@shared/hooks'
import { formatDatetime } from '@shared/utils'

const useStyles = tss.withName('TokenImportDetail').create(({ theme }) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    width: '100%',
  },
  sectionContainer: {
    display: 'flex',
    padding: '24px',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
    alignSelf: 'stretch',
    marginBottom: '20px',
    boxShadow: 'none',
    border: 'none',
  },
  titleContainer: {
    width: '100%',
    textAlign: 'left',
    marginBottom: '20px',
  },
  title: {
    color: theme.palette['neutral-700'],
    fontFamily: 'Inter',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  label: {
    color: theme.palette['neutral-500'],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
  },
  value: {
    color: theme.palette['neutral-900'],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
    display: 'flex',
    gap: '8px',
  },
  failed: {
    color: 'red',
  },
}))

export default function TokenImportDetail() {
  const { classes } = useStyles()
  const { id } = useParams()
  const navigate = useNavigate()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { t } = useTranslation()
  const { setNotification } = useNotification()
  const [tokenDetailsData, setTokenDetailsData] = useState<
    TokenImportDetails[]
  >([])
  const [tokenData, setTokenData] = useState<TokenImport>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [isLoadingRevert, setIsLoadingRevert] = useState(false)
  const [isRevertTokenImportModalOpen, setIsRevertTokenImportModalOpen] =
    useState<boolean>(false)

  const TokenImportDetailGridPrivs = useMemo(
    () => ['v2admin.tokenimportdetails.get'],
    []
  )

  const fetchTokenDetails = async (token_import_id: string) => {
    try {
      const response = await api.service('token-import-details').find({
        query: { filter: { token_import_id } },
      })
      return response
    } catch (error) {
      console.error('Error fetching token details:', error)
    }
  }

  const fetchTokenData = async (token_import_id: string) => {
    try {
      return await api.service('token-imports').get(token_import_id)
    } catch (error) {
      console.error('Error fetching token data:', error)
    }
  }

  const loadData = async (token_import_id: string) => {
    setLoading(true)
    try {
      const [tokenImportsDetailsResponse, tokenImportsResponse] =
        await Promise.all([
          fetchTokenDetails(token_import_id),
          fetchTokenData(token_import_id),
        ])
      setTokenDetailsData(tokenImportsDetailsResponse || [])
      setTokenData(tokenImportsResponse)
    } catch (error) {
      console.error('Error fetching data:', error)
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    setAppBarTitle(
      t('ftp-feature-mfe-token.token-import.token-import-details'),
      null,
      [t('common.settings'), t('common.token-import')],
      `/merchant/token-import`
    )
  }, [])

  useEffect(() => {
    loadData(id)
  }, [id])

  const handleCloseRevertTokenImportModal = () => {
    setIsRevertTokenImportModalOpen(false)
  }

  const handleRevertTokenImport = async () => {
    setIsLoadingRevert(true)
    try {
      await api.service('token-imports').revert(id)
      await loadData(id)

      setNotification({
        type: 'success',
        label: t(
          'ftp-feature-mfe-token.token-import.token-reverted-successfully'
        ),
      })
    } catch (error) {
      setNotification({
        type: 'error',
        label: error.message,
      })
    } finally {
      setIsLoadingRevert(false)
      handleCloseRevertTokenImportModal()
    }
  }

  return (
    <>
      <section className={classes.container}>
        <HasPermission
          allPermissions={TokenImportDetailGridPrivs}
          unauthorizedComponent={<UserNotAllowed />}
        >
          {loading ? (
            <Loading />
          ) : (
            <>
              <Paper className={classes.sectionContainer}>
                <div className={classes.titleContainer}>
                  <Typography className={classes.title}>
                    {t('common.name-file-details')}
                  </Typography>
                </div>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Typography className={classes.label}>
                      {t('common.name-file')}
                    </Typography>
                    <Typography className={classes.value}>
                      {tokenData?.filename ?? '-'}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.label}>
                      {t('common.status')}
                    </Typography>
                    <TokenImportStatus statusCode={tokenData?.status} />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.label}>
                      {t('common.location')}
                    </Typography>
                    <Typography className={classes.value}>
                      {!tokenData?.location
                        ? '-'
                        : (tokenData?.location as Location)?.name}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.label}>
                      {t(
                        'ftp-feature-mfe-token.token-import.update-contacts-already-exist'
                      )}
                    </Typography>
                    <Typography className={classes.value}>
                      {tokenDetailsData.length > 0 &&
                      tokenDetailsData[0]?.update_existing
                        ? t('common.yes')
                        : t('common.no')}
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
              {tokenDetailsData.length > 0 &&
                tokenDetailsData.map((details, index) => (
                  <Paper key={index} className={classes.sectionContainer}>
                    <div className={classes.titleContainer}>
                      <Typography className={classes.title}>
                        {details.operation_type === 'import'
                          ? t(
                              'ftp-feature-mfe-token.token-import.import-results'
                            )
                          : t(
                              'ftp-feature-mfe-token.token-import.revert-results'
                            )}
                      </Typography>
                    </div>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <Typography className={classes.label}>
                          {t('common.time-started')}
                        </Typography>
                        <Typography className={classes.value}>
                          {formatDatetime(details.created_ts) ?? '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.label}>
                          {t(
                            'ftp-feature-mfe-token.token-import.token-records'
                          )}
                        </Typography>
                        <Box display="flex" alignItems="center">
                          <Typography
                            className={classes.value}
                            style={{ marginRight: '16px' }}
                          >
                            {t('common.successful')}:
                            <span>{details.tokens_affected || '0'}</span>
                          </Typography>
                          <Typography className={classes.value}>
                            {t('common.failed')}:
                            <span className={classes.failed}>
                              {details.tokens_failed}
                            </span>
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography className={classes.label}>
                          {t('common.contact-records')}
                        </Typography>
                        <Box display="flex" alignItems="center">
                          <Typography
                            className={classes.value}
                            style={{ marginRight: '16px' }}
                          >
                            {t('common.successful')}:
                            <span> {details.contacts_affected || '0'}</span>
                          </Typography>
                          <Typography className={classes.value}>
                            {t('common.failed')}:
                            <span className={classes.failed}>
                              {details.contacts_failed}
                            </span>
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </Paper>
                ))}
              <AppBar
                sx={{
                  bottom: 0,
                  top: 'auto',
                  position: 'fixed',
                  boxShadow: '0px -12px 79.9px 0px rgba(0, 0, 0, 0.10)',
                }}
              >
                <ButtonBar style={{ marginBottom: '0 !important' }}>
                  <ButtonBarEnd>
                    <Button
                      label={t('common.back')}
                      color="secondary"
                      testId="token-import-back-button"
                      style={{ marginLeft: '8px' }}
                      onClick={() => navigate(-1)}
                      guidingId="tokenimportdetail-back"
                    />
                    {tokenData?.status === 'new' && (
                      <Button
                        label={t(
                          'ftp-feature-mfe-token.token-import.edit-header-mappping'
                        )}
                        color="primary"
                        testId="token-import-edit-mapping-button"
                        style={{ marginLeft: '8px' }}
                        onClick={() =>
                          navigate(`/merchant/token-import/${id}/mapping`)
                        }
                      />
                    )}
                    {tokenData?.status === 'processed' &&
                    tokenDetailsData.some(
                      (details) =>
                        (details.tokens_affected as number) > 0 ||
                        details.contacts_affected > 0
                    ) ? (
                      <Button
                        label={t(
                          'ftp-feature-mfe-token.token-import.revert-token-import'
                        )}
                        color="primary"
                        testId="token-import-revert-button"
                        style={{ marginLeft: '8px' }}
                        onClick={() => setIsRevertTokenImportModalOpen(true)}
                        guidingId="tokenimportdetail-revert"
                      />
                    ) : null}
                  </ButtonBarEnd>
                </ButtonBar>
              </AppBar>
            </>
          )}
        </HasPermission>
      </section>
      <ActionModal
        open={isRevertTokenImportModalOpen}
        title={t('ftp-feature-mfe-token.token-import.revert-token-import')}
        buttons={[
          {
            label: t('common.cancel'),
            onClick: handleCloseRevertTokenImportModal,
            color: 'secondary',
          },
          {
            label: t('common.revert'),
            onClick: handleRevertTokenImport,
            color: 'primary',
            isLoading: isLoadingRevert,
          },
        ]}
        onClose={handleCloseRevertTokenImportModal}
      >
        <Typography sx={{ fontSize: '14px' }} variant="body2">
          {t('ftp-feature-mfe-token.token-import.are-sure-revert-token-import')}
        </Typography>
      </ActionModal>
    </>
  )
}
