import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'

import { SelectComponent } from '@shared/components'

export interface Time {
  hour: string
  minute: string
  period: 'AM' | 'PM'
}

interface TimeSelectorProps {
  value: Time
  onChange: (time: Time) => void
}

const TimeSelector: FC<TimeSelectorProps> = ({ value, onChange }) => {
  const { t } = useTranslation()

  const hourOptions = Array.from({ length: 12 }, (_, i) => ({
    value: (i + 1).toString(),
    label: (i + 1).toString(),
  }))

  const minuteOptions = Array.from({ length: 60 }, (_, i) => ({
    value: i.toString(),
    label: i.toString(),
  }))

  return (
    <Box display="flex" width="100%" gap="8px" justifyContent="center">
      <SelectComponent
        options={hourOptions}
        label={t('common.hour')}
        value={value.hour}
        onChange={(event) => onChange({ ...value, hour: event.target.value })}
        isOnAgGrid
      />

      <SelectComponent
        options={minuteOptions}
        label={t('common.minute')}
        value={value.minute}
        onChange={(event) => onChange({ ...value, minute: event.target.value })}
        isOnAgGrid
      />
      <ToggleButtonGroup
        value={value.period}
        exclusive
        onChange={(_, newPeriod) => {
          if (newPeriod === value.period || !newPeriod) return
          onChange({ ...value, period: newPeriod })
        }}
        style={{
          height: '40px',
          marginTop: 'auto',
        }}
      >
        <ToggleButton value="AM">
          <Typography>AM</Typography>
        </ToggleButton>
        <ToggleButton value="PM">
          <Typography>PM</Typography>
        </ToggleButton>
      </ToggleButtonGroup>
    </Box>
  )
}

export default TimeSelector
