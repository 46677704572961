import { yupResolver } from '@hookform/resolvers/yup'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { FormProvider, useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'
import * as yup from 'yup'

import {
  Grid,
  InputLabel,
  Typography,
  Button as MuiButton,
  AppBar,
  Box,
  Alert,
} from '@mui/material'

import { api, Location, TokenImport } from '@shared/api/src'
import {
  HasPermission,
  UserNotAllowed,
  ButtonBar,
  ButtonBarEnd,
  Button,
  GetItemsOptions,
  Typeahead,
  SelectComponent,
  SelectOption,
  Loading,
} from '@shared/components'
import ProcessingComponent from '@shared/components/feedback/processing/ProcessingComponent'
import Notification from '@shared/components/notification/Notification'
import { useFtpPortalHubCommunication } from '@shared/hooks'
import { getLocationNameService, toArrayFieldErrors } from '@shared/utils'

import { ApplyMappingModal } from '@/components/modals/apply-mapping-modal/ApplyMappingModal'
import { CancelTokenImportModal } from '@/components/modals/cancel-mapping-modal/CancelMappingModal'
import DryRunModal from '@/components/modals/dry-run-modal/DryRunModal'
import SaveMappingModal from '@/components/modals/save-mapping-modal/SaveMappingModal'

import FileImportPreview from './token-import-preview/TokenImportPreview'

const useStyles = tss.withName('TokenImportAdd').create(({ theme }) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    padding: '20px',
    width: '100%',
    marginLeft: '1px',
    overflowX: 'hidden',
  },
  inputLabel: {
    color: theme.palette['neutral-700'],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
  },
  hiddenInput: {
    display: 'none',
  },
  fadedContent: {
    opacity: 0.5,
  },
  title: {
    color: theme.palette['neutral-700'],
    fontFamily: 'Inter',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
    marginBottom: '20px',
  },
  label: {
    color: theme.palette['neutral-600'],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
  },
  value: {
    color: theme.palette['neutral-900'],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
  },
  customButton: {
    display: 'flex',
    padding: '8px 12px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '6px',
    border: `1px solid ${theme.palette['neutral-300']}`,
    background: theme.palette['neutral-50'],
    cursor: 'pointer',
    marginTop: '16px',
  },
  buttonText: {
    color: theme.palette['neutral-700'],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
  },
  fileInputContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
    border: `1px solid ${theme.palette['neutral-300']}`,
    borderRadius: '4px',
    height: '200px',
    cursor: 'pointer',
  },
  fileInputText: {
    color: theme.palette['neutral-700'],
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '24px',
  },
  fileInputSubText: {
    color: theme.palette['neutral-500'],
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
  },
  scrollableContainer: {
    maxHeight: '80vh',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  fileImportPreviewContainer: {
    marginBottom: '24px',
  },
  loadingContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: theme.palette.background.default,
  },
  notificationContainer: {
    position: 'fixed',
    top: '16px',
    right: '16px',
    transform: 'translateX(0)',
    zIndex: 1000,
  },
  alertContainer: {
    width: '100%',
    margin: '16px 0',
    maxHeight: '400px',
    overflowY: 'auto',
  },
  fileName: {
    display: 'block',
    maxWidth: 'calc(100% - 20px)',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    color: theme.palette['neutral-900'],
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '20px',
  },
}))

const schema = yup.object().shape({
  location_id: yup.string().required('Location is required'),
  update_contact_if_exists: yup
    .boolean()
    .required('Update Existing is required'),
  file: yup
    .mixed()
    .required('File is required')
    .test('fileFormat', 'Only .pgp files are accepted', (value) => {
      return value && (value as File).name.endsWith('.pgp')
    }),
})

const TokenImportAdd = () => {
  const { classes } = useStyles()
  const { id } = useParams()
  const navigate = useNavigate()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { t } = useTranslation()
  const [selectedLocation, setSelectedLocation] = useState<Location | null>(
    null
  )
  const [tokenPreviewData, setTokenPreviewData] = useState<TokenImport | null>(
    null
  )
  const [loadingPreview, setLoadingPreview] = useState<boolean>(false)
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [previewLoaded, setPreviewLoaded] = useState<boolean>(false)
  const [isApplyMappingModalOpen, setIsApplyMappingModalOpen] =
    useState<boolean>(false)
  const [isSaveModalOpen, setIsSaveModalOpen] = useState<boolean>(false)
  const [selectedHeaders, setSelectedHeaders] = useState<object>({})
  const [isCancelModalOpen, setCancelModalOpen] = useState<boolean>(false)
  const [showProcessing, setShowProcessing] = useState<boolean>(false)
  const [isDryRunButtonDisabled, setIsDryRunButtonDisabled] =
    useState<boolean>(true)
  const [isDryRunModalOpen, setIsDryRunModalOpen] = useState<boolean>(false)
  const [tokenImportData, setTokenImportData] = useState<TokenImport | null>(
    null
  )
  const [locationName, setLocationName] = useState<string>('')
  const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [selectedFileBase64, setSelectedFileBase64] = useState<string | null>(
    null
  )
  const [parentError, setParentError] = useState<string>('')
  const [detailedErrorMessages, setDetailedErrorMessages] = useState<string[]>(
    []
  )
  const [rowsWithErrorCount, setRowsWithErrorCount] = useState<number>(0)
  const [errorsByHeader, setErrorsByHeader] = useState({})
  const [fieldMappingsSelected, setFieldMappingsSelected] = useState<object>({})

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
    defaultValues: { update_contact_if_exists: true },
  })

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
  } = methods
  const fileInputRef = useRef(null)

  const tokenImportAddPrivs = [
    'v2admin.tokenimports.post',
    'v2admin.tokenimports.post.process',
  ]

  useEffect(() => {
    setAppBarTitle(
      t('ftp-feature-mfe-token.token-import.mapping-headers'),
      null,
      [t('common.settings'), t('common.token-import')],
      `/merchant/token-import`
    )
  }, [previewLoaded])

  const locationService = useMemo(() => {
    return (options: GetItemsOptions) => {
      if (!options.search || options.search.trim() === '') {
        return Promise.resolve([])
      }
      return getLocationNameService(options?.search ?? undefined)
    }
  }, [])

  const getLocationLabel = (location: Location) => location.name

  const handleUpdateChange = (event) => {
    const value = event.target.value === 'yes'
    setValue('update_contact_if_exists', value, { shouldValidate: true })
  }

  const getTokenPreview = async (token_import_id: string) => {
    setLoadingPreview(true)
    setPreviewLoaded(false)
    try {
      const data = (await api
        .service('token-imports')
        .preview(token_import_id)) as TokenImport
      setTokenPreviewData(data)
      setShowPreview(true)
    } catch (error) {
      console.error(error)
    } finally {
      setLoadingPreview(false)
      setPreviewLoaded(true)
    }
  }

  const getTokenImportInfo = async (id: string) => {
    try {
      const response = await api
        .service('token-imports')
        .find({ query: { filter: { id } } })
      setTokenImportData(response[0])
    } catch (error) {
      console.error('Error fetching token data:', error)
    }
  }

  const getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const result = reader.result
        if (typeof result === 'string') {
          const base64String = result.replace('data:', '').replace(/^.+,/, '')
          resolve(base64String)
        } else {
          reject(new Error('Error converting file to base64'))
        }
      }
      reader.onerror = (error) => reject(error)
    })
  }

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0]
    if (file) {
      setSelectedFile(file)
      setValue('file', file, { shouldValidate: true })

      try {
        const base64String = await getBase64(file)
        setSelectedFileBase64(base64String)
      } catch (error) {
        console.error('Error converting file to base64:', error)
      }
    }
  }

  const onSubmit = async (data) => {
    const payload = {
      ...data,
      file: {
        file_name: selectedFile?.name,
        content: selectedFileBase64,
      },
    }

    setLoadingPreview(true)
    try {
      const response = await api.service('token-imports').create(payload)
      setTokenImportData(response)
      getTokenPreview(response.id)
    } catch (error) {
      const errors = toArrayFieldErrors(error)

      if (errors.length > 0) {
        const errorMessage = errors.map((err) => err.message).join(', ')
        setParentError(errorMessage)
      }
      setLoadingPreview(false)
    }
  }

  useEffect(() => {
    if (id) {
      getTokenImportInfo(id)
      getTokenPreview(id)
    }
  }, [id])

  const fetchLocationInfo = async (location_id) => {
    try {
      const locationInfo = await api
        .service('locations')
        .getInfo(location_id, {})
      setLocationName(locationInfo.name)
    } catch (error) {
      console.error('Error fetching location info:', error)
      setLocationName('-')
    }
  }

  useEffect(() => {
    if (tokenImportData?.location_id) {
      fetchLocationInfo(tokenImportData.location_id)
    }
  }, [tokenImportData])

  const selectOptions: SelectOption<string>[] = [
    { value: 'yes', label: t('common.yes') },
    { value: 'no', label: t('common.no') },
  ]

  const onChangeLocation = (location: Location | null) => {
    setSelectedLocation(location)
    setValue('location_id', location?.id ?? undefined)
  }

  const handleOpenApplyMappingModal = () => setIsApplyMappingModalOpen(true)
  const handleCloseApplyMappingModal = () => setIsApplyMappingModalOpen(false)
  const handleOpenSaveModal = () => setIsSaveModalOpen(true)
  const handleCloseSaveModal = () => setIsSaveModalOpen(false)
  const handleCloseDryRunModal = () => setIsDryRunModalOpen(false)

  const handleApplyMapping = async (mappingData) => {
    if (mappingData) {
      const id = getTokenImportId()
      const data = (await api
        .service('token-imports')
        .patch(id, { token_import_mapping_id: mappingData.id })) as TokenImport
      setFieldMappingsSelected(mappingData.field_mappings)
    }
    handleCloseApplyMappingModal()
  }

  const handleProcessImport = async () => {
    handleCloseDryRunModal()
    setShowProcessing(true)
    const tokenImportId = getTokenImportId()
    await api.service('token-imports').process(tokenImportId)
    setShowProcessing(false)
    navigate(`/merchant/token-import/${tokenImportId}/detail`)
  }

  const processDryRunErrors = (dryRunResponse) => {
    const errorsByHeader = {}
    const uniqueMessages = new Set<string>()
    if (Array.isArray(dryRunResponse?.rows_with_error)) {
      dryRunResponse.rows_with_error.forEach((row) => {
        if (Array.isArray(row?.contact?.errors)) {
          row.contact.errors.forEach((error) => {
            let field = error.context?.key

            if (field) {
              const message = error.message
              uniqueMessages.add(`${field} - ${message}`)

              if (!errorsByHeader[field]) {
                errorsByHeader[field] = {}
              }

              const rowNumber = row.row_number
              errorsByHeader[field][rowNumber] = message
            }
          })
        }

        if (Array.isArray(row?.token?.errors)) {
          row.token.errors.forEach((error) => {
            let field = error.context?.key

            if (field) {
              const message = error.message
              uniqueMessages.add(`${field} - ${message}`)

              if (!errorsByHeader[field]) {
                errorsByHeader[field] = {}
              }

              const rowNumber = row.row_number
              errorsByHeader[field][rowNumber] = message
            }
          })
        }
      })
    }
    setDetailedErrorMessages(Array.from(uniqueMessages))
    return errorsByHeader
  }

  const handleDryRun = async () => {
    setErrorsByHeader({})
    setDetailedErrorMessages([])
    setRowsWithErrorCount(0)

    const tokenImportId = getTokenImportId()
    try {
      const response = (await api
        .service('token-imports')
        .dryRun(tokenImportId)) as TokenImport
      setRowsWithErrorCount(response?.rows_with_error_count as number)

      const processedErrors = processDryRunErrors(response)
      setErrorsByHeader(processedErrors)

      setIsDryRunModalOpen(true)
    } catch (error) {
      if (error?.message) {
        setParentError(error.message)
      }
      console.error(error)
    }
  }

  const extractAvailableColumns = (headers) => {
    const columns = []

    const flattenObject = (obj) => {
      for (const key in obj) {
        if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
          flattenObject(obj[key])
        } else {
          columns.push(obj[key])
        }
      }
    }

    flattenObject(headers)
    return columns
  }

  const getTokenImportId = () => {
    return id || tokenImportData?.id
  }

  const handleCloseCancelModal = () => setCancelModalOpen(false)
  const availableColumns = extractAvailableColumns(selectedHeaders)

  const hasSelectedValues = (headers) => {
    if (typeof headers !== 'object' || Object.keys(headers).length === 0) {
      return false
    }

    return Object.values(headers).some((value) => {
      if (typeof value === 'object' && value !== null) {
        return hasSelectedValues(value)
      }

      return typeof value === 'string' && value.length > 0
    })
  }

  return (
    <FormProvider {...methods}>
      {showProcessing ? (
        <ProcessingComponent
          title={t('ftp-feature-mfe-token.token-import.processing-token')}
          processingText={t(
            'ftp-feature-mfe-token.token-import.leave-this-page-check-process'
          )}
          showActionButton={true}
          actionButtonText={t('common.back-to-main-page')}
          actionButtonOnClick={() => navigate('/merchant/token-import/')}
        />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <section
            className={`${loadingPreview ? classes.fadedContent : ''} ${
              classes.scrollableContainer
            }`}
          >
            <HasPermission
              allPermissions={tokenImportAddPrivs}
              unauthorizedComponent={<UserNotAllowed />}
            >
              <>
                {parentError && (
                  <Box className={classes.notificationContainer}>
                    <Notification
                      label={parentError}
                      type="error"
                      show={true}
                      onClose={() => setParentError('')}
                    />
                  </Box>
                )}
                {id && loadingPreview ? (
                  <div className={classes.loadingContainer}>
                    <Loading />
                  </div>
                ) : !id && loadingPreview ? (
                  <ProcessingComponent
                    title={t('common.processing')}
                    processingText={t(
                      'ftp-feature-mfe-token.token-import.wait-few-minutes-until-file-processed'
                    )}
                  />
                ) : previewLoaded && showPreview ? (
                  <>
                    <Grid
                      container
                      spacing={1}
                      className={classes.container}
                      style={{ marginTop: '20px' }}
                    >
                      <Grid item xs={12}>
                        <Typography className={classes.title}>
                          {t('ftp-feature-mfe-token.token-import.import-token')}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.label}>
                          {t('common.location')}
                        </Typography>
                        <Typography className={classes.value}>
                          {selectedLocation?.name || locationName}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.label}>
                          {t(
                            'ftp-feature-mfe-token.token-import.update-contacts'
                          )}
                        </Typography>
                        <Typography className={classes.value}>
                          {tokenImportData
                            ? tokenImportData.update_contact_if_exists
                              ? t('common.yes')
                              : t('common.no')
                            : methods.getValues('update_contact_if_exists')
                            ? t('common.yes')
                            : t('common.no')}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.label}>
                          {t('common.imported-file')}
                        </Typography>
                        <Typography className={classes.fileName}>
                          {selectedFile
                            ? selectedFile.name
                            : tokenImportData
                            ? tokenImportData.filename
                            : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <Typography className={classes.label}>
                          {t('common.total-records')}
                        </Typography>
                        <Typography className={classes.value}>
                          {tokenImportData?.row_count || '-'}
                        </Typography>
                      </Grid>
                    </Grid>
                    {detailedErrorMessages.length > 0 && (
                      <Box className={classes.alertContainer}>
                        {detailedErrorMessages.map((message, index) => (
                          <Alert key={index} severity="error">
                            {message}
                          </Alert>
                        ))}
                      </Box>
                    )}
                    <Grid
                      container
                      spacing={1}
                      className={`${classes.container} ${classes.fileImportPreviewContainer}`}
                      style={{ marginTop: '20px' }}
                    >
                      <FileImportPreview
                        data={tokenPreviewData}
                        externalSelectedHeaders={fieldMappingsSelected}
                        onHeadersChange={setSelectedHeaders}
                        errorsByHeader={errorsByHeader}
                        onDryRunButtonDisabled={setIsDryRunButtonDisabled}
                      />
                    </Grid>
                  </>
                ) : (
                  <Grid container spacing={1} className={classes.container}>
                    {previewLoaded && (
                      <Grid item xs={12}>
                        <Typography className={classes.title}>
                          {t('common.token-imports')}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item xs={6}>
                      <Controller
                        name="location_id"
                        control={control}
                        render={({ field }) => (
                          <Typeahead<Location>
                            {...field}
                            id="location-typeahead"
                            testId="location-typeahead"
                            label={t('common.location')}
                            debounceMs={300}
                            matchItemAndValueProperty="name"
                            value={selectedLocation}
                            placeholder={t('common.location-placeholder')}
                            getItems={locationService}
                            getItemLabel={getLocationLabel}
                            onChangeValue={onChangeLocation}
                            noOptionsText={t('common.location-search')}
                            required
                            guidingId="tokenimportadd-location"
                          />
                        )}
                      />
                    </Grid>

                    <Grid item xs={6}>
                      <Controller
                        name="update_contact_if_exists"
                        control={control}
                        render={({ field }) => (
                          <>
                            <InputLabel
                              test-id="select-account-label"
                              variant="standard"
                              htmlFor="uncontrolled-native"
                              required
                              className={classes.inputLabel}
                              sx={{
                                '.MuiInputLabel-asterisk': { color: '#ff0000' },
                              }}
                            >
                              {t(
                                'ftp-feature-mfe-token.token-import.update-contacts-already-exist'
                              )}
                            </InputLabel>
                            <SelectComponent
                              {...field}
                              value={field.value ? 'yes' : 'no'}
                              options={selectOptions}
                              onChange={(event) => {
                                const value = event.target.value
                                field.onChange(value === 'yes')
                                handleUpdateChange(event)
                              }}
                              multiple={false}
                              sort={false}
                              style={{
                                minWidth: '100%',
                                width: '100%',
                                marginTop: '10px',
                                height: '45px',
                              }}
                              guidingId="tokenimportadd-updatecontact"
                            />
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography className={classes.inputLabel}>
                        {t(
                          'ftp-feature-mfe-token.token-import.select-file-to-import-pgp'
                        )}
                      </Typography>
                      <label
                        htmlFor="contained-button-file"
                        className={classes.fileInputContainer}
                      >
                        <input
                          id="contained-button-file"
                          type="file"
                          accept=".pgp"
                          onChange={(e) => {
                            handleFileChange(e)
                            setValue('file', e.target.files[0], {
                              shouldValidate: true,
                            })
                          }}
                          className={classes.hiddenInput}
                          ref={fileInputRef}
                          data-guiding-id="tokenimportadd-file"
                        />
                        {selectedFile ? (
                          <Typography className={classes.fileInputText}>
                            {selectedFile.name}
                          </Typography>
                        ) : (
                          <>
                            <Typography className={classes.fileInputText}>
                              {t('common.upload-file')}
                            </Typography>
                            <Typography className={classes.fileInputSubText}>
                              {t(
                                'ftp-feature-mfe-token.token-import.must-be-pgp-format'
                              )}
                            </Typography>
                          </>
                        )}
                        <MuiButton
                          className={classes.customButton}
                          onClick={() => fileInputRef.current.click()}
                          data-guiding-id="tokenimportadd-uploadfile"
                        >
                          <Typography className={classes.buttonText}>
                            {selectedFile
                              ? t('common.choose-other-file')
                              : t('common.choose-file')}
                          </Typography>
                        </MuiButton>
                      </label>
                      {errors.file && (
                        <Typography variant="body2" color="error">
                          {errors.file.message}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                )}
                {!(id && loadingPreview) && (
                  <AppBar
                    sx={{
                      bottom: 0,
                      top: 'auto',
                      position: 'fixed',
                      boxShadow: '0px -12px 79.9px 0px rgba(0, 0, 0, 0.10)',
                    }}
                  >
                    <ButtonBar style={{ marginBottom: '0 !important' }}>
                      <ButtonBarEnd>
                        {!previewLoaded && !showPreview ? (
                          <>
                            <Button
                              label={t('common.cancel')}
                              color="secondary"
                              testId="back-button"
                              style={{ marginRight: '8px' }}
                              onClick={() =>
                                navigate('/merchant/token-import/')
                              }
                              guidingId="tokenimportadd-cancel"
                            />
                            <Button
                              label={t('common.preview')}
                              color="primary"
                              type="submit"
                              disabled={!isValid}
                              guidingId="tokenimportadd-preview"
                            >
                              {t('common.preview')}
                            </Button>
                          </>
                        ) : (
                          previewLoaded && (
                            <>
                              <Button
                                label={t('common.cancel')}
                                color="secondary"
                                testId="cancel-preview-button"
                                style={{ marginLeft: '8px' }}
                                onClick={() =>
                                  navigate('/merchant/token-import/')
                                }
                                guidingId="tokenimportadd-cancelpreview"
                              />
                              <Button
                                label={t(
                                  'ftp-feature-mfe-token.token-import.dry-run'
                                )}
                                color="secondary"
                                testId="dry-run-button"
                                style={{ marginLeft: '8px' }}
                                onClick={handleDryRun}
                                disabled={isDryRunButtonDisabled}
                                guidingId="tokenimportadd-dryrun"
                              />
                              <Button
                                label={t(
                                  'ftp-feature-mfe-token.token-import.apply-mapping-template'
                                )}
                                color="secondary"
                                testId="apply-mapping-button"
                                style={{ marginLeft: '8px' }}
                                onClick={handleOpenApplyMappingModal}
                                guidingId="tokenimportadd-applymapping"
                              />
                              <Button
                                label={t(
                                  'ftp-feature-mfe-token.token-import.save-mapping'
                                )}
                                color="primary"
                                testId="save-mapping-button"
                                style={{ marginLeft: '8px' }}
                                onClick={handleOpenSaveModal}
                                disabled={!hasSelectedValues(selectedHeaders)}
                                guidingId="tokenimportadd-savemapping"
                              />
                            </>
                          )
                        )}
                      </ButtonBarEnd>
                    </ButtonBar>
                  </AppBar>
                )}
              </>
            </HasPermission>
          </section>
        </form>
      )}
      <ApplyMappingModal
        open={isApplyMappingModalOpen}
        availableColumns={availableColumns}
        handleClose={handleCloseApplyMappingModal}
        onApply={handleApplyMapping}
        notifyParentError={(error) => {
          setParentError(error)
        }}
        guidingId="tokenimport-applymapping"
      />
      <DryRunModal
        open={isDryRunModalOpen}
        handleClose={handleCloseDryRunModal}
        rowWithErrorCount={rowsWithErrorCount}
        onProcessImport={handleProcessImport}
        guidingId="tokenimport-dryrun"
      />
      {Object.keys(selectedHeaders).length > 0 && (
        <SaveMappingModal
          open={isSaveModalOpen}
          onDryRunButtonDisabled={setIsDryRunButtonDisabled}
          handleClose={handleCloseSaveModal}
          tokenImportId={tokenImportData?.id}
          locationId={selectedLocation?.id || tokenImportData?.location_id}
          selectedHeaders={selectedHeaders}
          handleDryRun={handleDryRun}
          notifyParentError={(error, detailedMessages) => {
            setParentError(error)
            setDetailedErrorMessages(detailedMessages)
          }}
          guidingId="tokenimport-savemapping"
        />
      )}
      <CancelTokenImportModal
        open={isCancelModalOpen}
        handleClose={handleCloseCancelModal}
        guidingId="tokenimport-canceltokenimport"
      />
    </FormProvider>
  )
}

export default TokenImportAdd
