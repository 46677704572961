import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Modal, Box, Typography } from '@mui/material'

import { ActionModal, Button } from '@shared/components'

const useStyles = tss.withName('DryRunModal').create(({ theme }) => ({
  modalBox: {
    width: '520px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    gap: '24px',
  },
  description: {
    alignSelf: 'stretch',
    color: theme.palette['neutral-700'],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: 'normal',
  },
  failed: {
    color: theme.palette['neutral-900'],
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: 'normal',
  },
}))

export const DryRunModal = ({
  open,
  handleClose,
  rowWithErrorCount,
  onProcessImport,
  guidingId,
}) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)

  const recordLabel =
    rowWithErrorCount === 1 ? t('common.record') : t('common.records')

  const handleProcessImport = async () => {
    setIsLoading(true)
    try {
      await onProcessImport()
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ActionModal
      open={open}
      onClose={handleClose}
      title={t('ftp-feature-mfe-token.token-import.dry-run-completed')}
      buttons={[
        {
          label: t('ftp-feature-mfe-token.edit-mapping'),
          color: 'secondary',
          onClick: handleClose,
          guidingId: `${guidingId}-editmapping`,
        },
        {
          label: t('ftp-feature-mfe-token.process-import'),
          color: 'primary',
          onClick: handleProcessImport,
          isLoading,
          guidingId: `${guidingId}-processimport`,
        },
      ]}
      className={classes.modalBox}
    >
      <Box className={classes.container}>
        <Typography
          id="dry-run-modal-description"
          className={classes.description}
        >
          {t(
            'ftp-feature-mfe-token.token-import.fix-any-failures-or-proceed-with-processing-import-file'
          )}
        </Typography>
        <Typography className={classes.failed}>
          {t('common.failed')}:{' '}
          <span
            style={{
              color: rowWithErrorCount > 0 ? '#EF4444' : 'inherit',
            }}
          >
            {rowWithErrorCount} {recordLabel}
          </span>
        </Typography>
        <Typography className={classes.description}>
          {t(
            'ftp-feature-mfe-token.token-import.click-edit-mapping-below-to-attempt-to-fix-any-failures'
          )}
        </Typography>
      </Box>
    </ActionModal>
  )
}

export default DryRunModal
