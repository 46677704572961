import { useTranslation } from 'react-i18next'

import { StatusProps, Status } from '@shared/components'

import { codeToTokenImportStatus } from '../../../mapping/token-imports'
import { TokenImportType } from '../../../types/tokenImportStatusCodes'

interface TokenImportStatusProps {
  statusCode: string
  testID?: string
}

export const TokenImportStatus = ({ statusCode }: TokenImportStatusProps) => {
  const { t } = useTranslation()

  const getStatus = (status: TokenImportType): StatusProps => {
    let statusData: StatusProps = {
      text: undefined,
      backgroundColor: undefined,
    }

    switch (status) {
      case TokenImportType.NEW:
        statusData.text = t('common.new')
        statusData.backgroundColor = 'dark-green-cyan'
        break
      case TokenImportType.PROCESSING:
        statusData.text = t('common.processing')
        statusData.backgroundColor = 'yellow'
        break
      case TokenImportType.PROCESSED:
        statusData.text = t('common.processed')
        statusData.backgroundColor = 'teal'
        break
      case TokenImportType.REVERTED:
        statusData.text = t('common.reverted')
        statusData.backgroundColor = 'gray'
        break
      default:
        statusData.text = t('common.unknown')
        statusData.backgroundColor = 'gray'
        break
    }
    return statusData
  }

  const { text, backgroundColor } = getStatus(
    codeToTokenImportStatus(statusCode)
  )

  return <Status text={text} backgroundColor={backgroundColor} />
}
