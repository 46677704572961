import { api, Location, Page } from '../../api/src'

type LocationServiceFn = (keyword: string) => Promise<Page<Location>>

export const getLocationNameService: LocationServiceFn = async (
  keyword: string
) => {
  const service = api.service('location-searches')
  const result = await service.find({
    query: {
      keyword: JSON.stringify(keyword),
    },
    paginate: true,
  })

  return result as Page<Location>
}
