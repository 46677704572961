export const ContactFields = [
  { name: 'Last Name', key: 'last_name' },
  { name: 'Account Number', key: 'account_number_contact' },
  { name: 'First Name', key: 'first_name' },
  { name: 'Cell Phone', key: 'cell_phone' },
  { name: 'Home Phone', key: 'home_phone' },
  { name: 'Office Phone', key: 'office_phone' },
  { name: 'Office Phone Extension', key: 'office_phone_ext' },
  { name: 'Address', key: 'address.street' },
  { name: 'City', key: 'address.city' },
  { name: 'State', key: 'address.state' },
  { name: 'Zip Code', key: 'address.postal_code' },
  { name: 'Country', key: 'address.country' },
  { name: 'Date of Birth', key: 'date_of_birth' },
  { name: 'Email', key: 'contact_email' },
  { name: 'Email Transaction Receipt?', key: 'email_trx_receipt' },
  { name: 'Contact API ID', key: 'contact_api_id' },
  { name: 'Contact Balance', key: 'balance' },
  { name: 'Contact C1', key: 'contact_c1' },
  { name: 'Contact C2', key: 'contact_c2' },
  { name: 'Contact C3', key: 'contact_c3' },
]

export const TokenFields = [
  { name: 'Account Number', key: 'account_number' },
  { name: 'Routing', key: 'routing_number' },
  { name: 'Account Holder Name', key: 'account_holder_name' },
  { name: 'Account Type', key: 'account_type' },
  { name: 'Token API ID', key: 'token_api_id' },
  { name: 'Token_C1', key: 'token_c1' },
  { name: 'Token_C2', key: 'token_c2' },
  { name: 'Token_C3', key: 'token_c3' },
  { name: 'SEC Code', key: 'ach_sec_code' },
  { name: 'Customer ID', key: 'customer_id' },
  { name: 'DL Number', key: 'identity_verification.dl_number' },
  { name: 'DL State', key: 'identity_verification.dl_state' },
  { name: 'DOB Year', key: 'identity_verification.dob_year' },
  { name: 'SSN4', key: 'identity_verification.ssn4' },
  { name: 'Expiration Date', key: 'exp_date' },
  { name: 'Expiration Month', key: 'exp_date.month' },
  { name: 'Expiration Year', key: 'exp_date.year' },
  { name: 'Is Company?', key: 'is_company' },
  { name: 'Payment Method', key: 'payment_method' },
  { name: 'Run AVS', key: 'run_avs' },
  { name: 'Address', key: 'billing_address.street' },
  { name: 'City', key: 'billing_address.city' },
  { name: 'State', key: 'billing_address.state' },
  { name: 'Zip Code', key: 'billing_address.postal_code' },
  { name: 'Country', key: 'billing_address.country' },
  { name: 'Phone', key: 'billing_address.phone' },
  { name: 'IP Address', key: 'terms_agree_ip' },
  { name: 'Title', key: 'title' },
]
