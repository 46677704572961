interface FilterModelItem {
  filterType: string
  filter?: string
  dateFrom?: string
  dateTo?: string
  values?: string[]
}

interface FilterModel {
  [key: string]: FilterModelItem
}

export const transformFilters = (filterModel: FilterModel) => {
  const filter = Object.entries(filterModel).reduce(
    (acc: any, [key, value]) => {
      if (value.filterType === 'text') {
        if (key.includes('.')) {
          const [key1, key2] = key.split('.')
          if (acc[key1]) {
            acc[key1][key2] = value.filter
          } else {
            acc[key1] = {}
            acc[key1][key2] = value.filter
          }
        } else {
          acc[key] = value.filter
        }
      } else if (value.filterType === 'date') {
        acc[key] = {}
        if (value.dateFrom) {
          acc[key]['$gte'] = new Date(value.dateFrom).getTime() / 1000
        }
        if (value.dateTo) {
          const dateTo = new Date(value.dateTo)
          dateTo.setDate(dateTo.getDate() + 1)
          acc[key]['$lte'] = dateTo.getTime() / 1000
        } else if (!value.dateTo && value.dateFrom) {
          const dateTo = new Date(value.dateFrom)
          dateTo.setDate(dateTo.getDate() + 1)
          acc[key]['$lte'] = dateTo.getTime() / 1000
        }
      } else if (value.filterType === 'set') {
        if (value.values && value.values.length > 0) {
          if (key.includes('.')) {
            const [key1, key2] = key.split('.')
            if (acc[key1]) {
              acc[key1][key2] = value.values.join(',')
            } else {
              acc[key1] = {}
              acc[key1][key2] = value.values.join(',')
            }
          } else {
            acc[key] = value.values.join(',')
          }
        }
      } else if (value.filterType === 'number') {
        if (key.includes('.')) {
          const [key1, key2] = key.split('.')
          if (acc[key1]) {
            acc[key1][key2] = value.filter
          } else {
            acc[key1] = {}
            acc[key1][key2] = value.filter
          }
        } else {
          acc[key] = value.filter
        }
      }
      return acc
    },
    {}
  )
  return filter
}
