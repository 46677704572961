import { LicenseManager } from 'ag-grid-enterprise'
import { useEffect, useMemo, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { I18nextProvider } from 'react-i18next'
import { BrowserRouter, Routes } from 'react-router-dom'

import { ThemeProvider } from '@mui/material'

import packageJSON from '@/../package.json'

import AddressablePage from '@shared/addressable-page-manager/src/AddressablePage'
import AddressablePageManager from '@shared/addressable-page-manager/src/AddressablePageManager'
import { User, api } from '@shared/api/src'
import { ErrorHandler } from '@shared/components'
import AppTheme from '@shared/design'
import {
  EnforceLoginProvider,
  AuthorizationProvider,
  FtpPortalHubCommunicationProvider,
  useFtpPortalHubCommunication,
  NotificationProvider,
} from '@shared/hooks'

import TokenImportAdd from '@/pages/token-import-add/TokenImportAdd'
import TokenImportDetail from '@/pages/token-import-detail/TokenImportDetail'
import TokenImportGrid from '@/pages/token-import-grid/TokenImportGrid'
import TokenImportMappingGrid from '@/pages/token-import-mapping-grid/TokenImportMappingGrid'

import i18n from './i18n'

LicenseManager.setLicenseKey(process.env.AG_GRID_LICENSE)

export const LOCAL_STORAGE_SESSION_TOKEN_KEY = 'token'

const TokenImportApp = () => {
  const [user, setUser] = useState<User | null>(null)

  const [addressablePageManager, setAddressablePageManager] =
    useState<AddressablePageManager>()

  const { setPortalVersion } = useFtpPortalHubCommunication()

  const pages = useMemo(() => {
    const root_url = `${window?.location?.pathname?.split('/token-import')[0]}`
    return [
      new AddressablePage(
        'Token Import',
        <TokenImportGrid></TokenImportGrid>,
        ['v2admin.tokenimports.get'],
        `${root_url}/token-import`,
        undefined,
        true
      ),
      new AddressablePage(
        '',
        <TokenImportAdd></TokenImportAdd>,
        ['v2admin.tokenimports.post', 'v2admin.tokenimports.post.process'],
        `${root_url}/token-import/add`,
        undefined,
        true
      ),
      new AddressablePage(
        '',
        <TokenImportDetail></TokenImportDetail>,
        [],
        `${root_url}/token-import/:id/detail`,
        undefined,
        true
      ),
      new AddressablePage(
        'Templates',
        <TokenImportMappingGrid></TokenImportMappingGrid>,
        [],
        `${root_url}/token-import/mapping`,
        undefined,
        true
      ),
      new AddressablePage(
        '',
        <TokenImportAdd></TokenImportAdd>,
        ['v2admin.tokenimports.post', 'v2admin.tokenimports.post.process'],
        `${root_url}/token-import/:id/mapping`,
        undefined,
        true
      ),
    ]
  }, [])

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const fetchedUser: any = await getUser()
        setUser(fetchedUser)
      } catch (error) {
        console.error('ERROR GETTING/SETTING USER DATA:', error.toString())
      }
    }

    fetchUser()
    setPortalVersion({ version: packageJSON?.version })
  }, [])

  useEffect(() => {
    if (!user) return
    setAddressablePageManager(new AddressablePageManager(pages, user))
  }, [user])

  async function getUser() {
    const user = await api.service('users').authorize()

    setUser(user)
    return user
  }

  if (!addressablePageManager) return null

  return (
    <AuthorizationProvider user={user}>
      <ThemeProvider theme={AppTheme}>
        <Routes>{addressablePageManager.renderAllAddressablePages()}</Routes>
      </ThemeProvider>
    </AuthorizationProvider>
  )
}

const TokenImport: React.FC = () => {
  return (
    <FtpPortalHubCommunicationProvider>
      <ErrorBoundary FallbackComponent={ErrorHandler}>
        <EnforceLoginProvider redirectToLogin>
          <I18nextProvider i18n={i18n}>
            <BrowserRouter>
              <NotificationProvider>
                <TokenImportApp />
              </NotificationProvider>
            </BrowserRouter>
          </I18nextProvider>
        </EnforceLoginProvider>
      </ErrorBoundary>
    </FtpPortalHubCommunicationProvider>
  )
}

export default TokenImport
