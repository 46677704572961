import React from 'react'
import { tss } from 'tss-react/mui'

import { Typography, Box } from '@mui/material'

import { Loading, Button } from '@shared/components'

const useStyles = tss.withName('ProcessingComponent').create(({ theme }) => ({
  loadingContainer: {
    position: 'fixed',
    top: 0,
    left: 20,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.background.paper,
    zIndex: 1000,
    flexDirection: 'column',
    height: '400px',
    margin: '100px 30px 100px 270px',
  },
  noOverlay: {
    backgroundColor: 'transparent',
  },
  title: {
    paddingTop: '20px',
    color: theme.palette['neutral-700'],
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: 'normal',
  },
  processingText: {
    paddingTop: '20px',
    color: theme.palette['neutral-700'],
    textAlign: 'center',
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
    width: '358px',
  },
  buttonContainer: {
    marginTop: '20px',
  },
}))

export interface ProcessingComponentProps {
  title: string
  processingText: string
  showOverlay?: boolean
  showActionButton?: boolean
  actionButtonText?: string
  actionButtonOnClick?: () => void
}

const ProcessingComponent: React.FC<ProcessingComponentProps> = ({
  title,
  processingText,
  showOverlay = true,
  showActionButton = false,
  actionButtonText,
  actionButtonOnClick,
}) => {
  const { classes } = useStyles()
  return (
    <div
      className={`${classes.loadingContainer} ${
        !showOverlay ? classes.noOverlay : ''
      }`}
    >
      <Loading style={{ height: '10px', marginBottom: '20px' }} />
      <Typography className={`${classes.title}`}>{title}</Typography>
      <Typography className={classes.processingText}>
        {processingText}
      </Typography>
      {showActionButton && (
        <Box className={classes.buttonContainer}>
          <Button
            onClick={actionButtonOnClick}
            color="secondary"
            label={actionButtonText}
          />
        </Box>
      )}
    </div>
  )
}

export default ProcessingComponent
