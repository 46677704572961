import { IServerSideGetRowsParams, SortModelItem } from 'ag-grid-community'

import { ServiceTypes, api } from '../../api/src'

export interface FilterModelItem {
  filterType: string
  filter?: string
  dateFrom?: string
  dateTo?: string
  values?: string[]
  type?: string
}

export interface FilterModel {
  [colId: string]: FilterModelItem
}

export type CustomDataSourceFilterFunction = (
  acc: {},
  key: string,
  value: FilterModelItem
) => boolean

// CustomDataSourceFilters allows for custom filtering where desired. If you
// pass CustomDataSourceFilters into formatFilter, formatFilter will check to
// see if you have a custom filter defined for the current column's filterType.
// If so, it will call the *custom filter* first to allow it to perform
// filtering if deems custom filtering is appropriate. For instance the
// CustomDataSourceFilterFunction may choose to filter certain keys (columns)
// specially, but let other keys be filtered using the standard filtering from
// formatFilter.
//
// Note that CustomDataSourceFilterFunction should return true if it did indeed
// apply a special filter to this key, and false if it did not. If false is
// returned, formatFilter will fall through and continue forward with its
// normal filtering.

export interface CustomDataSourceFilters {
  [key: string]: CustomDataSourceFilterFunction
}

const handleMonetaryAmountFilter: CustomDataSourceFilterFunction = (
  acc,
  key,
  value
) => {
  if (key.includes('amount') && value.filterType === 'number' && value.filter) {
    const filterValue = value.filter
    if (filterValue) {
      const numericValue = parseFloat(filterValue)
      if (!isNaN(numericValue)) {
        acc[key] = Math.round(numericValue).toString()
        return true
      }
    }
  }
  return false
}

const formatFilter = (
  filterModel: FilterModel,
  customFilters: CustomDataSourceFilters = {}
) => {
  const filter = Object.entries(filterModel).reduce((acc, [key, value]) => {
    // Allow for custom filtering
    if (customFilters.hasOwnProperty(value.filterType)) {
      const filterApplied = customFilters[value.filterType](acc, key, value)
      if (filterApplied) {
        return acc
      }
    }

    const accumulator = acc as { [key: string]: any }

    if (value.filterType === 'text') {
      if (key.includes('.')) {
        const [key1, key2] = key.split('.')
        if (accumulator[key1]) {
          accumulator[key1][key2] = value.filter
        } else {
          accumulator[key1] = {}
          accumulator[key1][key2] = value.filter
        }
      } else {
        accumulator[key] = value.filter
      }
    } else if (value.filterType === 'date') {
      accumulator[key] = {}
      if (value.dateFrom) {
        accumulator[key]['$gte'] = new Date(value.dateFrom).getTime() / 1000
      }
      if (value.dateTo) {
        const dateTo = new Date(value.dateTo)
        dateTo.setDate(dateTo.getDate() + 1)
        accumulator[key]['$lte'] = dateTo.getTime() / 1000 - 1
      } else if (value.dateFrom && value.type !== 'greaterThan') {
        const dateTo = new Date(value.dateFrom)
        dateTo.setDate(dateTo.getDate() + 1)
        accumulator[key]['$lte'] = dateTo.getTime() / 1000 - 1
      }
    } else if (value.filterType === 'set') {
      if (value.values && value.values.length > 0) {
        if (key.includes('.')) {
          const [key1, key2] = key.split('.')
          if (accumulator[key1]) {
            accumulator[key1][key2] = value.values.join(',')
          } else {
            accumulator[key1] = {}
            accumulator[key1][key2] = value.values.join(',')
          }
        } else {
          accumulator[key] = value.values.join(',')
        }
      }
    } else if (value.filterType === 'number') {
      if (key.includes('.')) {
        const [key1, key2] = key.split('.')
        if (accumulator[key1]) {
          accumulator[key1][key2] = value.filter
        } else {
          accumulator[key1] = {}
          accumulator[key1][key2] = value.filter
        }
      } else {
        accumulator[key] = value.filter
      }
    }
    return acc
  }, {})
  return filter
}

const formatSort = (sortModel: SortModelItem[]) => {
  const sort = sortModel.reduce((acc, { colId, sort }) => {
    const accumulator = acc as { [key: string]: any }
    if (colId.includes('.')) {
      const [colId1, colId2] = colId.split('.')
      accumulator[colId1] = {}
      accumulator[colId1][colId2] = sort
    } else {
      accumulator[colId] = sort
    }
    return acc
  }, {})
  return sort
}

interface Params {
  [key: string]: string
}

/**
 * DataSource to get data from the api on server side model
 * @param service the service to use from feathers
 */
export function DataSource(
  service: keyof Omit<ServiceTypes, 'authentication' | 'users/login'>,
  extraParams: Params = {},
  customFilters: CustomDataSourceFilters = {},
  clientFilter?: (data: unknown[]) => unknown[]
) {
  this.getRows = (params: IServerSideGetRowsParams) => {
    const { sortModel, filterModel = {} } = params.request

    customFilters = {
      number: handleMonetaryAmountFilter,
    }

    const pageSize = params.api.paginationGetPageSize()
    const currentPage = params.api.paginationGetCurrentPage() + 1

    const filter = formatFilter(filterModel, customFilters)
    const sort = formatSort(sortModel)

    params.api.hideOverlay()

    const originalLocation = window.location.href

    api
      .service(service)
      .find({
        query: {
          filter: filter,
          sort: sort,
          page: {
            size: pageSize,
            number: currentPage,
          },
          ...extraParams,
        },
        paginate: true,
      })
      .then((result) => {
        const currentLocation = window.location.href
        const data = clientFilter ? clientFilter(result.list) : result.list

        /* The goal of URL comparison is to avoid memory leaks warnings when we left the page before the datasource loads completely,
          destroying the grid and making it impossible for the datasource to set the values */
        if (originalLocation === currentLocation) {
          params.success({
            rowData: data,
            rowCount: result.pagination.total_count,
          })

          if (!data.length) params.api.showNoRowsOverlay()
        }
      })
      .catch(() => {
        params.success({
          rowData: [],
          rowCount: 0,
        })

        params.api.showNoRowsOverlay()
      })
  }
}
