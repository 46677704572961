import React from 'react'
import SvgIcon, { SvgIconOwnProps } from '@mui/material/SvgIcon'
import { CommonProps } from '@mui/material/OverridableComponent'

export const OptionsGrid = (
  props: JSX.IntrinsicAttributes & {
    component: React.ElementType<any>
  } & SvgIconOwnProps &
    CommonProps &
    Omit<
      any,
      | 'color'
      | 'fontSize'
      | 'shapeRendering'
      | 'style'
      | 'classes'
      | 'children'
      | 'className'
      | 'sx'
      | 'viewBox'
      | 'htmlColor'
      | 'inheritViewBox'
      | 'titleAccess'
    >
) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clipPath="url(#clip0_3352_139465)">
          <path
            d="M4.16667 8.33203C3.25 8.33203 2.5 9.08203 2.5 9.9987C2.5 10.9154 3.25 11.6654 4.16667 11.6654C5.08333 11.6654 5.83333 10.9154 5.83333 9.9987C5.83333 9.08203 5.08333 8.33203 4.16667 8.33203ZM15.8333 8.33203C14.9167 8.33203 14.1667 9.08203 14.1667 9.9987C14.1667 10.9154 14.9167 11.6654 15.8333 11.6654C16.75 11.6654 17.5 10.9154 17.5 9.9987C17.5 9.08203 16.75 8.33203 15.8333 8.33203ZM10 8.33203C9.08333 8.33203 8.33333 9.08203 8.33333 9.9987C8.33333 10.9154 9.08333 11.6654 10 11.6654C10.9167 11.6654 11.6667 10.9154 11.6667 9.9987C11.6667 9.08203 10.9167 8.33203 10 8.33203Z"
            fill="#0369A1"
          />
        </g>
        <defs>
          <clipPath id="clip0_3352_139465">
            <rect width="20" height="20" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </SvgIcon>
  )
}
