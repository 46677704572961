import { IFilterParams } from 'ag-grid-community'
import { DateTime } from 'luxon'
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import {
  Box,
  TextField,
  Divider,
  List,
  Paper,
  ListItemButton,
  Grid,
} from '@mui/material'
import { StaticDatePicker } from '@mui/x-date-pickers'
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'

import { Button } from '@shared/components'
import AppTheme from '@shared/design'
import { useEnforceLogin } from '@shared/hooks'

import TimeSelector, { Time } from './TimeSelector'

const useStyles = tss.withName('DateRangeFilter').create(() => ({
  customListItemText: {
    color: '#495057',
    fontSize: '0.875rem',
  },
  textFieldCentered: {
    width: '92%',
    marginTop: '10px',
    marginLeft: '10px',
    '.MuiInputBase-input': {
      fontSize: '0.875rem',
      color: '#495057',
      textAlign: 'center',
    },
  },
  datePicker: {
    '.MuiPickersStaticWrapper-staticWrapperRoot': {
      padding: '8px',
    },
    '.MuiCalendarPicker-root': {
      '& .MuiIconButton-root': {
        padding: '4px',
      },
      '& .MuiTypography-caption': {
        fontSize: '0.75rem',
      },
    },
  },
  buttonsGroup: {
    width: '100%',
    paddingRight: '10px',
  },
}))

interface IDateRangeFilterProps extends IFilterParams {
  typeOfSearch?: string
  filterType?: string
  customFormat?: string
  onApply: (filter: any) => void
  filterPropName: string
}

const DateRangeFilter = forwardRef((props: IDateRangeFilterProps, ref) => {
  const {
    typeOfSearch = 'equal',
    filterType = 'text',
    onApply,
    filterPropName,
    customFormat = null,
  } = props
  const { user } = useEnforceLogin()
  const { t } = useTranslation()
  const { classes } = useStyles(AppTheme)
  const [selectedRange, setSelectedRange] = useState<any>()
  const [selectedFromTime, setSelectedFromTime] = useState<Time>({
    hour: '12',
    minute: '0',
    period: 'am',
  })
  const [selectedToTime, setSelectedToTime] = useState<Time>({
    hour: '11',
    minute: '59',
    period: 'pm',
  })
  const [dateRangeText, setDateRangeText] = useState('')
  const [selectedPreview, setSelectedPreview] = useState<any>()

  const [showCustomDate, setShowCustomDate] = useState(false)
  const paperRef = useRef(null)

  let isMounted = true

  useEffect(() => {
    return () => {
      isMounted = false
    }
  }, [])

  const valueMap = {
    today: 'common.filter-today',
    yesterday: 'common.filter-yesterday',
    'this week': 'common.filter-this-week',
    'last week': 'common.filter-last-week',
    'last 30 days': 'common.filter-last-30-days',
    'this month': 'common.filter-this-month',
    'last month': 'common.filter-last-month',
  }

  useImperativeHandle(ref, () => ({
    isFilterActive: () => {
      return selectedRange !== ''
    },
    doesFilterPass: (params) => {
      return (
        typeof selectedRange === 'string' &&
        selectedRange.includes(params.value)
      )
    },
    getModel: () => {
      if (typeof selectedRange?.filter === 'object' && selectedRange !== null) {
        if (
          selectedRange &&
          selectedRange.filter &&
          selectedRange.filter.$gte &&
          selectedRange.filter.$lte
        ) {
          const { $gte, $lte } = selectedRange.filter
          let filter = {
            $gte: $gte,
            $lte: $lte,
          }

          if (customFormat) {
            filter = {
              $gte: DateTime.fromFormat($gte, customFormat)
                .setZone(user.tz)
                .toSeconds(),
              $lte: DateTime.fromFormat($lte, customFormat)
                .setZone(user.tz)
                .toSeconds(),
            }
          } else {
            filter = {
              $gte: Math.floor(
                DateTime.fromSeconds($gte).setZone(user.tz).toSeconds()
              ),
              $lte: Math.floor(
                DateTime.fromSeconds($lte).setZone(user.tz).toSeconds()
              ),
            }
          }

          setDateRangeText(
            formatDateRange(
              DateTime.fromSeconds(filter.$gte).setZone(user.tz),
              DateTime.fromSeconds(filter.$lte).setZone(user.tz)
            )
          )

          return {
            type: typeOfSearch,
            filterType: filterType,
            filter: filter,
          }
        }
      } else if (selectedRange?.start && selectedRange?.end) {
        let filter

        if (selectedRange?.start?.ts && selectedRange?.end?.ts) {
          const startDateTime = DateTime.fromJSDate(
            new Date(selectedRange.start.ts)
          )
          const endDateTime = DateTime.fromJSDate(
            new Date(selectedRange.end.ts)
          )
          if (customFormat) {
            filter = {
              $gte: startDateTime.startOf('day').toFormat(customFormat),
              $lte: endDateTime.endOf('day').toFormat(customFormat),
            }
          } else {
            filter = {
              $gte: Math.floor(startDateTime.toMillis() / 1000),
              $lte: Math.floor(endDateTime.toMillis() / 1000),
            }
          }
        } else {
          if (customFormat) {
            filter = {
              $gte: selectedRange.start.startOf('day').toFormat(customFormat),
              $lte: selectedRange.end.endOf('day').toFormat(customFormat),
            }
          } else {
            filter = {
              $gte: Math.floor(selectedRange.start.toMillis() / 1000),
              $lte: Math.floor(selectedRange.end.toMillis() / 1000),
            }
          }
        }

        if (customFormat) {
          setDateRangeText(
            formatDateRange(
              DateTime.fromFormat(filter.$gte, customFormat),
              DateTime.fromFormat(filter.$lte, customFormat)
            )
          )
        } else {
          setDateRangeText(
            formatDateRange(
              DateTime.fromSeconds(filter.$gte),
              DateTime.fromSeconds(filter.$lte)
            )
          )
        }

        return {
          type: typeOfSearch,
          filterType: filterType,
          filter: filter,
        }
      } else if (selectedRange) {
        let handleSelectedRange = ''
        if (
          typeof selectedRange === 'object' &&
          selectedRange !== null &&
          'filter' in selectedRange
        ) {
          handleSelectedRange = selectedRange.filter.toString()
        } else if (typeof selectedRange === 'string') {
          handleSelectedRange = selectedRange
        }

        if (handleSelectedRange) {
          updateDateRangeText(handleSelectedRange)
          return {
            type: typeOfSearch,
            filterType: filterType,
            filter: handleSelectedRange.toLowerCase(),
          }
        }
      }
    },

    setModel(model) {
      if (model) {
        if (model.filter?.$gte) {
          const time = DateTime.fromSeconds(model.filter.$gte).setZone(user.tz)
          setSelectedFromTime({
            hour: time.toFormat('h'),
            minute: time.toFormat('m'),
            period: time.toFormat('a').toLowerCase() as Time['period'],
          })
        }
        if (model.filter?.$lte) {
          const time = DateTime.fromSeconds(model.filter.$lte).setZone(user.tz)
          setSelectedToTime({
            hour: time.toFormat('h'),
            minute: time.toFormat('m'),
            period: time.toFormat('a').toLowerCase() as Time['period'],
          })
        }
        setSelectedRange(model)
        props.filterChangedCallback()
      } else {
        handleClear()
      }
    },
    setFilter: (filter) => {
      updateDateRangeText(filter)
    },
    getModelAsString: () => {
      return dateRangeText
    },
  }))

  const handleClear = async () => {
    if (!isMounted) return

    const handleClear = async () => {
      await setSelectedRange('')
      await setSelectedPreview('')
      setDateRangeText('')
      await setSelectedFromTime({
        hour: '12',
        minute: '0',
        period: 'am',
      })

      await setSelectedToTime({
        hour: '11',
        minute: '59',
        period: 'pm',
      })

      onApply(undefined)
      if (selectedRange) {
        props.filterChangedCallback()
      }
      props.api.hidePopupMenu()
    }
  }

  const handleApply = async () => {
    if (!selectedPreview) {
      return
    }

    if (selectedPreview?.start && selectedPreview?.end) {
      setSelectedRange('')
      await setDateRangeText(
        formatDateRange(selectedPreview.start, selectedPreview.end)
      )
      setSelectedRange({
        start: selectedPreview.start,
        end: selectedPreview.end,
      })
      setSelectedPreview('')
      setShowCustomDate(false)
    } else {
      if (selectedPreview === 'custom') {
        await setSelectedRange('')
      }

      await setSelectedRange(selectedPreview)
      await setDateRangeText(selectedPreview)
      setSelectedPreview('')
    }
    props.api.hidePopupMenu()
    props.filterChangedCallback()
  }

  const handleSelect = (range) => {
    setSelectedPreview(range)
    if (typeof range === 'string') {
      return
    } else {
      const { startDate, endDate } = calculateDateRange(range)
      setDateRangeText(formatDateRange(startDate, endDate))
    }
  }

  const calculateDateRange = (range) => {
    const today = DateTime.local().setZone(user.tz)
    let startDate, endDate

    switch (range) {
      case 'Today':
        startDate = endDate = today
        break
      case 'Yesterday':
        startDate = endDate = today.minus({ days: 1 })
        break
      case 'This Week':
        startDate = today.startOf('week')
        endDate = today.endOf('week')
        break
      case 'Last Week':
        startDate = today.minus({ weeks: 1 }).startOf('week')
        endDate = today.minus({ weeks: 1 }).endOf('week')
        break
      case 'Last 30 Days':
        startDate = today.minus({ days: 30 })
        endDate = today
        break
      case 'This Month':
        startDate = today.startOf('month')
        endDate = today.endOf('month')
        break
      case 'Last Month':
        startDate = today.minus({ months: 1 }).startOf('month')
        endDate = today.minus({ months: 1 }).endOf('month')
        break
      default:
        startDate = endDate = null
        break
    }

    return { startDate, endDate }
  }

  const formatDateRange = (start, end) => {
    if (start && end) {
      if (start.toISODate() === end.toISODate()) {
        return start.toFormat('MM/dd/yyyy')
      }
      return `${start.toFormat('MM/dd/yyyy')} - ${end.toFormat('MM/dd/yyyy')}`
    }
    return ''
  }

  const handleShowCustomDate = async (open?: boolean) => {
    setShowCustomDate(!showCustomDate)
    if (open) {
      setSelectedPreview(selectedRange)
    } else {
      setSelectedPreview('')
    }
  }

  const isOptionSelected = (option) => {
    return (
      selectedPreview === option ||
      (!selectedPreview &&
        (selectedRange?.filter === option || selectedRange === option))
    )
  }

  const updateDateRangeText = (option) => {
    const translationKey = valueMap[option.toLowerCase()] || option
    setDateRangeText(t(translationKey))
  }

  const isCustomSelected = () => {
    const dateRangeRegex = /^\d{2}\/\d{2}\/\d{4}( - \d{2}\/\d{2}\/\d{4})?$/
    return (
      dateRangeRegex.test(dateRangeText) ||
      Boolean(selectedRange?.start) ||
      Boolean(selectedPreview?.start)
    )
  }

  const assignTime = (date, time: Time) => {
    let hour = parseInt(time.hour)
    if (
      (time.period === 'pm' && time.hour !== '12') ||
      (time.period === 'am' && time.hour === '12')
    ) {
      hour = (hour + 12) % 24
    }

    date = date.setZone(user.tz).set({
      hour,
      minute: time.minute,
    })

    return date
  }

  const handleDateChange = (type, date) => {
    setSelectedPreview((prevRange) => {
      const updatedRange = { ...prevRange, [type]: date }

      if (updatedRange.start) {
        updatedRange.start = assignTime(updatedRange.start, selectedFromTime)
      }

      if (updatedRange.end) {
        updatedRange.end = assignTime(updatedRange.end, selectedToTime)
      }

      if (updatedRange.start > updatedRange.end) {
        updatedRange.end = null
      }

      return updatedRange
    })
  }

  const handleTimeChange = (type, time) => {
    if (type === 'start') {
      setSelectedFromTime(time)
    } else {
      setSelectedToTime(time)
    }

    setSelectedPreview((prevRange) => {
      const updatedRange = { ...prevRange }

      if (updatedRange.start && type === 'start') {
        updatedRange.start = assignTime(updatedRange.start, time)
      }

      if (updatedRange.end && type === 'end') {
        updatedRange.end = assignTime(updatedRange.end, time)
      }

      if (updatedRange.start > updatedRange.end) {
        updatedRange.end = null
      }

      return updatedRange
    })
  }

  const updateDateRangeFromFilter = () => {
    const { $gte, $lte } = selectedRange.filter || {}
    if ($gte && $lte) {
      if (customFormat) {
        setSelectedRange({
          start: DateTime.fromFormat($gte, customFormat).setZone(user.tz),
          end: DateTime.fromFormat($lte, customFormat).setZone(user.tz),
        })
      } else {
        setSelectedRange({
          start: DateTime.fromSeconds($gte).setZone(user.tz),
          end: DateTime.fromSeconds($lte).setZone(user.tz),
        })
      }
    }
  }

  useEffect(() => {
    if (selectedRange?.filter) {
      updateDateRangeFromFilter()
    }
  }, [selectedRange])

  return (
    <div ref={paperRef}>
      <Paper
        sx={{
          width: showCustomDate ? '680px' : '100%',
          height: '100%',
        }}
      >
        {showCustomDate ? (
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                p: 2,
              }}
            >
              <Grid container spacing={2} justifyContent="center">
                <Grid item xs={12} md={6}>
                  <TextField
                    label={t('common.date-start')}
                    value={
                      selectedPreview?.start
                        ? selectedPreview?.start.toFormat('MM/dd/yyyy hh:mm a')
                        : ''
                    }
                    className={classes.textFieldCentered}
                    InputProps={{ readOnly: true }}
                    size="small"
                    sx={{ marginBottom: 2 }}
                  />
                  <StaticDatePicker
                    className={classes.datePicker}
                    displayStaticWrapperAs="desktop"
                    openTo="day"
                    value={selectedPreview?.start}
                    onChange={(newDate) => handleDateChange('start', newDate)}
                    maxDate={selectedPreview?.end}
                    timezone={user.tz}
                  />
                  <TimeSelector
                    value={selectedFromTime}
                    onChange={(time) => handleTimeChange('start', time)}
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  <TextField
                    label={t('common.date-end')}
                    value={
                      selectedPreview?.end
                        ? selectedPreview?.end.toFormat('MM/dd/yyyy hh:mm a')
                        : ''
                    }
                    className={classes.textFieldCentered}
                    InputProps={{ readOnly: true }}
                    size="small"
                    sx={{ marginBottom: 2 }}
                  />
                  <StaticDatePicker
                    className={classes.datePicker}
                    displayStaticWrapperAs="desktop"
                    openTo="day"
                    value={selectedPreview?.end || null}
                    onChange={(newDate) => handleDateChange('end', newDate)}
                    minDate={selectedPreview?.start}
                    timezone={user.tz}
                  />
                  <TimeSelector
                    value={selectedToTime}
                    onChange={(time) => handleTimeChange('end', time)}
                  />
                </Grid>
              </Grid>
            </Box>

            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}
            >
              <Button
                className={classes.buttonsGroup}
                label={t('common.back')}
                onClick={() => handleShowCustomDate(false)}
                color={'secondary'}
              />

              <Button
                className={classes.buttonsGroup}
                label={t('common.clear')}
                onClick={handleClear}
                color={'secondary'}
              />
              <Button
                className={classes.buttonsGroup}
                label={t('common.apply')}
                onClick={handleApply}
                color={'primary'}
              />
            </Box>
          </LocalizationProvider>
        ) : (
          <>
            {Boolean(selectedRange?.start && !selectedPreview) ||
            selectedPreview === 'custom' ? (
              <TextField
                fullWidth
                variant="outlined"
                value={dateRangeText || ''}
                className={classes.textFieldCentered}
                InputProps={{
                  readOnly: true,
                }}
                size="small"
              />
            ) : null}
            <Divider />
            <List component="nav" aria-label="date range options">
              <ListItemButton
                selected={isOptionSelected('today')}
                onClick={() => handleSelect('today')}
              >
                <span className={classes.customListItemText}>
                  {t('common.filter-today')}
                </span>
              </ListItemButton>

              <ListItemButton
                selected={isOptionSelected('yesterday')}
                onClick={() => handleSelect('yesterday')}
              >
                <span className={classes.customListItemText}>
                  {t('common.filter-yesterday')}
                </span>
              </ListItemButton>

              <ListItemButton
                selected={isOptionSelected('this week')}
                onClick={() => handleSelect('this week')}
              >
                <span className={classes.customListItemText}>
                  {t('common.filter-this-week')}
                </span>
              </ListItemButton>

              <ListItemButton
                selected={isOptionSelected('last week')}
                onClick={() => handleSelect('last week')}
              >
                <span className={classes.customListItemText}>
                  {t('common.filter-last-week')}
                </span>
              </ListItemButton>

              <ListItemButton
                selected={isOptionSelected('last 30 days')}
                onClick={() => handleSelect('last 30 days')}
              >
                <span className={classes.customListItemText}>
                  {t('common.filter-last-30-days')}
                </span>
              </ListItemButton>

              <ListItemButton
                selected={isOptionSelected('this month')}
                onClick={() => handleSelect('this month')}
              >
                <span className={classes.customListItemText}>
                  {t('common.filter-this-month')}
                </span>
              </ListItemButton>

              <ListItemButton
                selected={isOptionSelected('last month')}
                onClick={() => handleSelect('last month')}
              >
                <span className={classes.customListItemText}>
                  {t('common.filter-last-month')}
                </span>
              </ListItemButton>

              <ListItemButton
                selected={
                  (!selectedPreview && isCustomSelected()) ||
                  selectedPreview === 'custom'
                }
                onClick={() => {
                  setSelectedPreview('custom')
                  handleShowCustomDate(true)
                }}
              >
                <span className={classes.customListItemText}>
                  {t('common.custom')}
                </span>
              </ListItemButton>
            </List>
            <Box
              sx={{ display: 'flex', justifyContent: 'space-between', p: 1 }}
            >
              <Button
                label={t('common.clear')}
                onClick={handleClear}
                color={'secondary'}
                style={{
                  width: '48%',
                }}
              />
              <Button
                label={t('common.apply')}
                onClick={handleApply}
                style={{
                  width: '48%',
                }}
              />
            </Box>
            <Divider />
          </>
        )}
      </Paper>
    </div>
  )
})

export default DateRangeFilter
