import { IStatusPanelParams, PaginationChangedEvent } from 'ag-grid-community'
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react'
import { tss } from 'tss-react/mui'

import { Pagination } from '@mui/material'

import AppTheme from '@shared/design'

const useStyles = tss.withName('PageSelector').create({
  container: {
    marginTop: '1rem',
  },
})

export default forwardRef((props: IStatusPanelParams, ref: any) => {
  const { classes } = useStyles(AppTheme)

  const [totalPages, setTotalPages] = useState(0)
  const [currentPage, setCurrentPage] = useState(1)
  const [hasData, setHasData] = useState(false)

  useEffect(() => {
    const paginationEvent = (event: PaginationChangedEvent) => {
      setTotalPages(event.api.paginationGetTotalPages())
      setCurrentPage(event.api.paginationGetCurrentPage() + 1)
      setHasData(event.api.paginationGetRowCount() > 0)
    }
    setTotalPages(props.api.paginationGetTotalPages())
    setCurrentPage(props.api.paginationGetCurrentPage() + 1)
    setHasData(props.api.paginationGetRowCount() > 0)

    props.api.addEventListener('paginationChanged', paginationEvent)

    return () => {
      props.api.removeEventListener('paginationChanged', paginationEvent)
    }
  }, [props.api])

  useEffect(() => {
    setTimeout(() => {
      props.api.paginationGoToPage(currentPage - 1)
    }, 0)
  }, [currentPage, props.api])

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCurrentPage(value)
  }

  useImperativeHandle(
    ref,
    () => {
      return {
        getTotalPages: () => totalPages,
        getCurrentPage: () => currentPage,
      }
    },
    [totalPages, currentPage]
  )

  if (!hasData) {
    return null
  }

  return (
    <div className={classes.container}>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handleChange}
        data-guiding-id={`${(props as any).guidingId}-pagenumber`}
      />
    </div>
  )
})
